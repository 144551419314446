<template>
  <el-dialog
    title="Nova Mensagem"
    :visible.sync="visible"
    :before-close="handleClose"
  >
    <el-input
      placeholder="Pesquisar"
      prefix-icon="el-icon-search"
      v-model="filtroClientes"
      clearable
      size="small"
      style="margin-bottom: 15px"
      @input="buscarClientes"
    />
    <div v-loading="loading">
      <div v-if="usuario.id != c.id"
        v-for="(c, i) in clientesFiltrados"
        :key="i"
      >
        <div
          class="box-cliente"
          @click="abrirChat(c)"
        >
          <div style="display: flex; align-items: center">
            <el-avatar icon="el-icon-user-solid" />
            <span>{{ c.nome }}</span>
          </div>
          <i class="el-icon-arrow-right" />
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import "../css/theme.css";
import AuthUtils from "../utils/AuthUtils";
import KonnectService from "../services/KonnectEnterpriseService";

export default {
  name: "NovaMensagem",
  props: { visible: Boolean },
  components: {},

  data() {
    return {
      clientes: [],
      clientesFiltrados: [],

      usuario: [],

      paginacao: {
        limit: 10,
        offset: 0,
        ffield: "id",
        order: "ASC",
        usaPaginacao: false,
        filterFields: "cpf,email",
        filterValue: "",
      },

      loading: false,
      filtroClientes: "",
    };
  },

  mounted() {
    this.listarClientes();
    this.usuario = AuthUtils.getUser();
  },
  methods: {
    handleClose() {
      this.$emit("closeModal", 0);
    },
    listarClientes() {
      if (AuthUtils.isRole(AuthUtils.roles.USUARIO_LIMITADO)) {
        this.clientes = [AuthUtils.getUser().organizacao];
        this.clientesFiltrados = [AuthUtils.getUser().organizacao];
      } else {
        this.loading = true;
        KonnectService.getClientes(this.paginacao)
          .then((r) => {
            this.clientes = JSON.parse(JSON.stringify(r.data));
            this.clientesFiltrados = JSON.parse(JSON.stringify(r.data));
          })
          .catch((e) => console.log(e))
          .finally(() => (this.loading = false));
      }
    },

    abrirChat(c) {
      this.loading = true;
      KonnectService.getCanalPrivado(c.id)
        .then((r) => { this.$emit("abrirChat", r.data); this.$emit("closeModal", 0); })
        .catch((e) =>
          this.$notify({
            title: "Erro ao abrir chat",
            message: e.response.headers["x-reason"],
            type: "warning",
          })
        )
        .finally(() => (this.loading = false));
    },
    buscarClientes(val) {
      this.clientesFiltrados = this.clientes.filter((u) =>
        u.nome.toLowerCase().includes(val.toLowerCase())
      );
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
>>> .el-dialog {
  margin-top: 5vh !important;
  width: 90% !important;
}

>>> .el-dialog {
  margin: 0 auto 50px !important;
}


>>> .el-avatar {
  color: #fff !important;
  background-color: #e5e5e5 !important;
  margin-right: 20px;
}

>>> .box-cliente {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 12px;
  border-bottom: 1px solid #dddddd;
  margin-bottom: 15px;
  justify-content: space-between;
}
</style>
