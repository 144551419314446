<template>
  <el-container class="conteudo" v-if="!showMidias">
    <div class="top-bar" />
    <el-header>
      <div class="chat-header">
        <el-button
          icon="el-icon-arrow-left ar"
          @click="$router.push({name: 'Chat'})"
        />
        <span>Informações</span>
      </div>
    </el-header>
    <el-divider />
    <el-main>
      <div
        v-if="data.status || !( this.data.dataDeConclusao > new Date().toISOString() )"
        class="box-status"
      >
        <i
          :class="data.status ? 'el-icon-warning i_s' : 'el-icon-success i_s'"
          :style="data.status ? 'color: #f56c6c' : 'color: #67C23A'"
        />
        <div>
          <p>Evento {{ data.status ? "expirado" : "concluído" }}</p>
          <span>
            {{ data.status ? "Expirado" : "Concluído" }} em
            <b>{{ tratarDataStatus }}</b>
          </span>
        </div>
      </div>
      <el-divider v-if="data.status || !( this.data.dataDeConclusao > new Date().toISOString() )" />
      <div class="avatar">
        <div class="user">
          <div @click="visualizarImagem = true">
            <el-avatar icon="el-icon-user-solid" :src="imageContainer"/>
            
          </div>
          <el-button v-if="data.tipo != 'PRIVADO' && (isAdmin ||
                  isRole('ROLE_ORGANIZACAO'))" icon="el-icon-upload" class="upload-icon" type="primary" @click="$refs.FileInput.click()" data-toggle="tooltip" title="Upload de imagem" circle>
          </el-button>
          <input ref="FileInput" type="file" style="display: none;" @change="onFileSelect"/>
        </div>
      </div>
      <div
        style="text-align: end; margin-right: 15px"
        v-if="data.tipo != 'PRIVADO' && ( this.data.dataDeConclusao > new Date().toISOString() ) && (isAdmin ||
                  isRole('ROLE_ORGANIZACAO'))"
      >
        <i
          class="fas fa-pen"
          @click="showModal = true"
        />
      </div>
      <el-input
        v-model="data.nome"
        readonly
      />
      <el-input
        v-if="data.tipo != 'PRIVADO'"
        v-model="data.descricao"
        placeholder="Não há descrição"
        readonly
        type="textarea"
        style="margin-top: 10px"
      />
      <div v-if="data.tipo == 'EVENTO'">
        <div class="box-info-evento">
          <span>
            <b>Data do inicio do evento:</b> {{ tratarData(data.dataDoEvento) }}
          </span>
          <span>
            <b>Data do fim do evento:</b> {{ tratarData(data.dataDeConclusao) }}
          </span>
          <span v-if="data.categoria">
            <b>Categoria:</b> {{ data.categoria.texto }}
          </span>
          <span><b>Prioridade:</b> {{ data.prioridade }}</span>
        </div>
        <el-divider />
      </div>
      <div
        class="box-arquivos"
        @click="abrirMidias"
      >
        <div style="text-align: initial">
          <span>Arquivos</span>
          <div class="text-arquivos">
            <i class="fas fa-paperclip ppr" />
            <p>Ver mídias e documentos</p>
          </div>
        </div>
        <el-button icon="el-icon-arrow-right ar" />
      </div>
      <el-divider v-if="data.tipo != 'PRIVADO'" />
      <div
        v-if="data.tipo != 'PRIVADO'"
        class="box-membros"
      >
        <p>{{ data.membros.length }} membros</p>
        <div
          class="box-canal bd"
          @click="dialogVisible = true"
          v-if="
            (data.status == 'ativo' || ( this.data.dataDeConclusao > new Date().toISOString() )) &&
            (isRole('ROLE_ORGANIZACAO') || (isRole('ROLE_CLIENTE_ADMINISTRADOR') && isAdmin))
          "
        >
          <div style="display: flex; align-items: center">
            <div class="circle-icon c2 c3">
              <i class="el-icon-plus pl" />
            </div>
            <span style="margin-left: 20px"> Adicionar membros </span>
          </div>
        </div>
        <div
          class="box-canal bd"
          v-for="(membro, i) in data.membros"
          :key="i"
        >
          <el-dropdown
            trigger="click"
            @command="handlerMembro"
          >
            <div style="display: flex; align-items: center;">
              <div class="circle-icon c2">
                <i class="fas fa-user p" />
              </div>
              <span style="margin-left: 20px"> {{ nomeMembro(membro) }} </span>
            </div>
            
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item class="removerMembro"
               icon="el-icon-delete-solid"
                v-if=" (isAdmin ||
                  isRole('ROLE_ORGANIZACAO')) && data.criadorId != membro.id 
                "
                :command="{action: 'apagarMembro', membro: membro}"
              >
                Remover membro
              </el-dropdown-item>
              <el-dropdown-item
                icon="el-icon-star-on"
                v-if="
                  (isAdmin ||
                  isRole('ROLE_ORGANIZACAO')) && data.criadorId != membro.id && membro.admin != true
                "
                :command="{action: 'promoverMembro', membro: membro}"
              >
                Promover a Administrador
              </el-dropdown-item>
              <el-dropdown-item
                icon="el-icon-remove"
                v-if="
                  (isAdmin ||
                  isRole('ROLE_ORGANIZACAO')) && data.criadorId != membro.id && membro.admin == true
                "
                :command="{action: 'rebaixarMembro', membro: membro}"
              >
                Remover Administrador
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <div v-if="data.criadorId == membro.id">
              <span>Dono do grupo</span>
          </div>
          <div v-else>
              <div v-if="membro.admin"> 
                <span v>Administrador do grupo</span>  
              </div>
          </div>
        </div>
      </div>
       <div
        v-if="data.tipo != 'PRIVADO' && isRole('ROLE_ORGANIZACAO') ||
                  isRole('ROLE_CLIENTE_ADMINISTRADOR')"
        style="display: flex"
      >
        <el-button
          v-if="isRole('ROLE_ORGANIZACAO')"
          icon="el-icon-delete-solid"
          class="bt-apagar"
          @click="apagarCanal"
        >
          Apagar {{ data.tipo == "GRUPO" ? "grupo" : "evento" }}
        </el-button>
        <el-button
          @click="concluirEvento"
          icon="el-icon-check ic"
          class="bt-conc"
          v-if="data.tipo == 'EVENTO' && !data.status && ( this.data.dataDeConclusao > new Date().toISOString() ) "
        >
          Concluir evento
        </el-button>
      </div>
    </el-main>

    <el-dialog
      title="Visualizar Imagem"
      :visible.sync="visualizarImagem"
      width="100%"
    >
      <img class="imgfull" :src="`${kmsx}canais-cover-organizacao-${data.organizacaoId}/${data._id}-cover-1024.png?${token}`" v-if="!imgError" @error="onImgError()"/>
      <p v-else>Não há uma imagem</p>
    </el-dialog>

    <el-dialog
      title="Adicionar membros"
      :visible.sync="dialogVisible"
      @opened="listarClientes"
      width="80%"
    >
      <div
        v-loading="loadingClientes"
        class="box-clientes"
        v-if="clientes.length > 0"
      >
        <el-checkbox
          v-model="checkAllUsuarios"
          @change="handleCheckAllChange"
        >
          Selecionar todos
        </el-checkbox>
        <el-checkbox-group
          style="display: flex; flex-direction: column"
          v-model="usuariosSelecionados"
          @change="handleSelected"
        >
          <el-checkbox
            v-for="(cliente, i) in clientes"
            :label="cliente"
            :key="i"
          >
            {{ cliente.nome }}
          </el-checkbox>
        </el-checkbox-group>
        <transition name="fade">
          <div class="box-bt-adc-membro">
            <el-button
              class="bt-send"
              v-if="selected"
              @click="adicionarMembros"
              :disabled="loadingAdcMembros"
            >
              <i
                v-if="!loadingAdcMembros"
                class="fas fa-share sh"
              />
              <i
                v-else
                class="el-icon-loading ld"
              />
            </el-button>
          </div>
        </transition>
      </div>
      <div v-else>
        <span>Todos os clientes já pertencem a esse canal</span>
      </div>
    </el-dialog>
    <editar-canal
      v-if="showModal"
      :visible="showModal"
      :data="data"
      @close="showModal = false"
      @alterar="alterarCanal"
    />
    <upload-foto
      v-if="showUpload"
      :visible="showUpload"
      :fileSelected ="fileSelected"
      :data="data"
      @close="showUpload = false"
      @alterar="alterarCanal"
    />
  </el-container>
  <el-container v-else>
    <midias
      @voltar="voltar"
      :data="data"
      @abrirEmMensagemEspecifica="abrirEmMensagemEspecifica"
    />
  </el-container>
</template>

<script>
import KonnectService from "../services/KonnectEnterpriseService.js";
import AuthUtils from "../utils/AuthUtils.js";
import EditarCanal from "./EditarCanal.vue";
import UploadFoto from "./UploadFoto.vue";
import Midias from "./Midias.vue";
import moment from "moment";
import eventBus from "../eventBus";
import "../css/theme.css";

export default {
  name: "InfoChat",
  components: { UploadFoto, EditarCanal, Midias },
  props: {},

  data() {
    return {
      isRole: AuthUtils.isRole,
      usuarioLogado: JSON.parse(window.localStorage.getItem("usuario")).id,

      loadingClientes: false,
      loadingAdcMembros: false,
      dialogVisible: false,
      selected: false,
      showMidias: false,
      showModal: false,
      showUpload: false,

      fileSelected: "",

      paginacao: {
        limit: 10,
        offset: 0,
        ffield: "id",
        order: "ASC",
        usaPaginacao: false,
        filterFields: "cpf,email",
        filterValue: "",
      },

      checkAllUsuarios: false,
      usuariosSelecionados: [],

      editMode: false,
      isAdmin: false,
      clientes: [],
      imageContainer:"",
      token:"",
      visualizarImagem: false,
      imgError: false,
      kmsx:process.env.VUE_APP_KMSX_SERVICE_AZURE_STORAGE,
    };
  },

  computed: {
    data() {
      return this.$store.getters.chatAtual;
    },
    tratarDataStatus() {
      if (this.data.status)
        return moment(this.data.dataDoEvento).format("DD/MM/YYYY HH:mm");
      else return moment(this.data.dataDeConclusao).format("DD/MM/YYYY HH:mm");
    },
    
   
  },

  mounted() {
    

    // Verifica se o usuário veio de um canal
    if (this.data == null) {
      this.$router.push({ name: "Mensagens" });
    }

    this.userAdmin();
    
    eventBus.$off("alteradoMembro");
    eventBus.$on("alteradoMembro", () => this.userAdmin());
    this.token = window.localStorage.getItem("TokenSAS")
    this.imageContainer = `${process.env.VUE_APP_KMSX_SERVICE_AZURE_STORAGE}canais-cover-organizacao-${this.data.organizacaoId}/${this.data._id}-cover-80.png?${this.token}`
  },

  methods: {
    userAdmin(){
      //busca e verifica se o usuário logado é um admin
      let membro = this.data.membros.find((x) => x.id === JSON?.parse(window?.localStorage?.getItem("usuario"))?.id)
      if(membro.admin == true)
        this.isAdmin = true
      else
        this.isAdmin = false
    },

    tratarData(d) {
      return moment(d).format("DD/MM/YYYY HH:mm");
    },

    voltar() {
      this.showMidias = false;
    },

     onFileSelect(e) {
      const file = e.target.files[0];
      let isImage = file.type.includes("image/");
      let isLt2M = file.size / 1024 / 1024 < 2;

      if (!isImage){ 
        this.$message.error("O arquivo deve ser uma imagem!");
        return;
      }
      if (!isLt2M){ 
        this.$message.error("O tamanho máximo não pode exceder 2Mb!");
        return;
      }
      this.$refs.FileInput.value = null;
      this.fileSelected = file;
      this.showUpload = true;
      
    },

    async toBase64(url) {
      const response = await fetch(url);
      const blob = await response.blob();
      return await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },

    nomeMembro(m) {
      if (m.id == this.usuarioLogado) return "Você";
      else return m.nome;
    },
    
    listarClientes() {
      this.loadingClientes = true;
      KonnectService.getClientes(this.paginacao)
        .then((r) => {
          this.tratarClientes(r.data);
        })
        .catch((e) =>
          this.$notify({
            title: "Erro ao listar os clientes",
            message: e.response.headers["x-reason"],
            type: "warning",
          }),
        )
        .finally(() => (this.loadingClientes = false));
    },

    tratarClientes(c) {
      var uf = c.filter((a1) => {
        return (
          this.data.membros.filter((a2) => {
            return a2.id == a1.id;
          }).length == 0
        );
      });
      this.clientes = uf;
    },

    abrirMidias() {
      this.showMidias = true;
    },

    handleCheckAllChange(val) {
      this.selected = val;
      this.usuariosSelecionados = val ? this.clientes : [];
    },

    handleSelected(val) {
      if (val.length == 0) this.selected = false;
      else this.selected = true;
    },

    alterarCanal(c) {
      this.data.nome = c.nome;
      this.data.descricao = c.descricao;
      if (c.tipo == "EVENTO") {
        this.data.prioridade = c.prioridade;
        this.data.dataDoEvento = c.dataDoEvento;
        this.data.categoria = c.categoria;
      }
    },

    abrirEmMensagemEspecifica(msg) {
      this.$router.go(-1);
      this.$store.dispatch("SET_DEVE_NAVEGAR_ATE_MENSAGEM", msg);
    },

    adicionarMembros() {
      this.loadingAdcMembros = true;
      let ids = [];
      this.usuariosSelecionados.forEach((u) => ids.push(u.id));

      KonnectService.postMembros(this.data, ids)
        .then((r) => {
          this.data.membros = r.data.membros;
          this.usuariosSelecionados = [];
          this.selected = false;
          this.tratarClientes(this.clientes);
          this.dialogVisible = false;
          this.$message({
            message: "Usuário adicionado",
            type: "success",
          });
        })
        .catch((e) =>
          this.$message({
            message: e.response.headers["x-reason"],
            type: "warning",
          })
        )
        .finally(() => (this.loadingAdcMembros = false));
    },

    handlerMembro(command){
      if(command.action === "apagarMembro") this.apagarMembro(command.membro)
      if(command.action === "promoverMembro") this.promoverMembro(command.membro)
      if(command.action === "rebaixarMembro") this.rebaixarMembro(command.membro)
    },

    apagarMembro(m) {
      if (m.id == this.usuarioLogado || m.id == this.data.criadorId)
        this.$message.error("Esse membro não pode ser removido.");
      else {
        this.$confirm(
          `Tem certeza que deseja remover <strong>"${m.nome}"</strong> do grupo "${this.data.nome}"?`,
          "Atenção!",
          {
            dangerouslyUseHTMLString: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Cancelar",
            type: "warning",
          }
        )
          .then(() => {
            KonnectService.deleteMembro(this.data, m.id)
              .then(() => {
                this.data.membros = this.data.membros.filter(
                  (c) => c.id != m.id
                );
                this.clientes.push(m);
                this.$message({
                  type: "success",
                  dangerouslyUseHTMLString: true,
                  message: `<strong>"${m.nome}"</strong> removido!`,
                });
              })
              .catch((e) =>
                this.$message({
                  title: "Erro ao remover membro",
                  message: e.response.headers["x-reason"],
                  type: "warning",
                })
              );
          })
          .catch(() => {});
      }
    },

    promoverMembro(m) {
      let reqBody = { promovido: true };
      if (m.id == this.usuarioLogado || m.id == this.data.criadorId)
        this.$message.error("Esse membro não pode ser promovido.");
      else {
        this.$confirm(
          `Tem certeza que deseja promover <strong>"${m.nome}"</strong> a administrador do grupo "${this.data.nome}"?`,
          "Atenção!",
          {
            dangerouslyUseHTMLString: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Cancelar",
            type: "warning",
          }
        )
          .then(() => {
            KonnectService.patchMembro(this.data, m.id, reqBody)
              .then((r) => {
                this.data.membros = r.data.membros;
                this.$message({
                  type: "success",
                  dangerouslyUseHTMLString: true,
                  message: `<strong>"${m.nome}"</strong> promovido!`,
                });
              })
              .catch((e) =>
                this.$message({
                  title: "Erro ao promover membro",
                  message: e.response.headers["x-reason"],
                  type: "warning",
                })
              );
          })
          .catch(() => {});
      }
    },

    rebaixarMembro(m) {
      let reqBody = { promovido: false };
      if (m.id == this.usuarioLogado || m.id == this.data.criadorId)
        this.$message.error("Esse membro não pode ser rebaixado.");
      else {
        this.$confirm(
          `Tem certeza que deseja remover dos adminitradores o <strong>"${m.nome}"</strong>" do grupo ${this.data.nome}"?`,
          "Atenção!",
          {
            dangerouslyUseHTMLString: true,
            confirmButtonText: "Sim",
            cancelButtonText: "Cancelar",
            type: "warning",
          }
        )
          .then(() => {
            KonnectService.patchMembro(this.data, m.id, reqBody)
              .then((r) => {
                this.data.membros = r.data.membros;
                this.$message({
                  type: "success",
                  dangerouslyUseHTMLString: true,
                  message: `<strong>"${m.nome}"</strong> promovido!`,
                });
              })
              .catch((e) =>
                this.$message({
                  title: "Erro ao rebaixar membro",
                  message: e.response.headers["x-reason"],
                  type: "warning",
                })
              );
          })
          .catch(() => {});
      }
    },

    apagarCanal() {
      this.$confirm(
        `Tem certeza que deseja excluir <strong>${this.data.nome}</strong>?`,
        "Atenção!",
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar",
          type: "warning",
        }
      )
        .then(() => {
          KonnectService.deleteCanal(this.data)
            .then((c) => {
              this.$message({
                type: "success",
                dangerouslyUseHTMLString: true,
                message: `<strong>"${this.data.nome}"</strong> excluído!`,
              });
              this.$router.push({ name: "Mensagens" });
            })
            .catch((e) =>
              this.$message({
                title: "Erro ao excluir o chat",
                message: e.response.headers["x-reason"],
                type: "warning",
              })
            );
        })
        .catch(() => {});
    },
    concluirEvento() {
      this.$confirm(
        `Tem certeza que deseja concluír <strong>${this.data.nome}</strong>?`,
        "Atenção!",
        {
          dangerouslyUseHTMLString: true,
          confirmButtonText: "Sim",
          cancelButtonText: "Cancelar",
          type: "warning",
        }
      )
        .then(() => {
          KonnectService.concluirEvento(this.data._id)
            .then((c) => {
              this.data.status = "concluido";
              this.$nextTick(() => this.$forceUpdate());
              this.$message({
                type: "success",
                dangerouslyUseHTMLString: true,
                message: `<strong>"${this.data.nome}"</strong> concluído!`,
              });
            })
            .catch((e) =>
              this.$message({
                title: "Erro ao concluir o evento",
                message: e.response.headers["x-reason"],
                type: "warning",
              })
            );
        })
        .catch(() => {});
    },
    onImgError() {
			this.imgError = true;
		},
  },
};
</script>
<style>
.conteudo{
  box-shadow: 0px 10px 10px 0px #aaa;
}
.chat-header {
  display: flex;
  align-items: center;
  height: 100%;
}
.chat-header span,
.ar {
  color: #03283f;
  font-weight: 600 !important;
}
</style>
<style scoped>
>>> .el-dialog {
  margin: 15vh auto 50px !important;
}

.imgfull {
  min-width: 200px; 
  max-width: 570px;
}

.box-info-evento b {
  font-weight: 500 !important;
  color: #03283f;
}

.fa-pen {
  color: #03283f;
  font-size: 13px;
}
.el-avatar {
  background-color: #d1d1d1 !important;
  width: 80px;
  height: 80px;
  font-size: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px 0 !important;
  cursor: pointer;
}



.avatar {
  display: flex;
  justify-content: center; 
}

.user {
  width: 100px;
  height: 100px;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  vertical-align: middle;
}

.upload-icon{
  background-color: #409EFF !important;
  position: absolute;
  top: 10px;
  right: 5px;
  font-size: 20px;
  align-items: center;
  justify-content: center;
  display: flex;
  vertical-align: middle;
  width: 30px;
  height: 30px;
}

.upload-icon:hover{
    background-color: #409EFF !important;
    color: #25d8c2 !important;
}

.box-arquivos {
  display: flex;
  justify-content: space-between;
  margin: 22px 0 0 20px;
}

.box-arquivos span,
.box-membros p {
  font-weight: 600;
  color: #03283f;
}

.box-info-evento span {
  color: #656565;
  font-size: 14px;
}
.ic {
  color: var(--segunda-cor) !important;
}
.text-arquivos {
  display: flex;
  align-items: center;
  margin: 10px 0 25px;
}

.text-arquivos p {
  color: #656565;
}

.ppr {
  color: var(--segunda-cor);
  margin-right: 15px;
}

.c2 {
  width: 42px;
  height: 42px;
}

.c3 {
  background-color: var(--segunda-cor);
}

.pl {
  color: #fff;
  font-weight: 600;
}

.bd {
  border-bottom: 0.5px solid #ececec;
}

.box-membros {
  text-align: initial;
  margin: 5px;
}

.box-membros p {
  margin: 15px;
}

.bt-apagar,
.bt-apagar:focus,
.bt-apagar:hover,
.bt-apagar:active {
  color: #f56c6c;
  margin-top: 10px;
  background-color: #fff !important;
}

.bt-conc,
.bt-conc:focus,
.bt-conc:hover,
.bt-conc:active {
  color: var(--segunda-cor);
  margin-top: 10px;
  background-color: #fff !important;
}

.box-clientes {
  display: flex;
  flex-direction: column;
  text-align: initial;
}

.box-bt-adc-membro {
  display: flex;
  justify-content: flex-end;
  height: 35px;
}

.bt-send,
.bt-send:focus,
.bt-send:hover {
  background: var(--segunda-cor);
  border-radius: 100%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sh {
  color: #fff;
}

.box-info-evento {
  display: flex;
  flex-direction: column;
  margin: 20px;
  align-items: flex-start;
}

/* ELEMENT UI */
.el-checkbox {
  font-weight: 400 !important;
  padding: 7px;
}
.removerMembro {
  color: #f56c6c !important;
}
</style>
