<template>
  <el-dialog
    title="Novo Grupo"
    :visible.sync="visible"
    :before-close="handleClose"
  >
    <div
      class="main-box"
      v-loading="loading"
    >
      <label>Nome do grupo <b style="color: red">*</b></label>
      <el-input
        size="small"
        maxlength="30"
        show-word-limit
        v-model="reqBody.nome"
      />
      <div class="margin">
        <label>Descrição</label>
        <el-input
          size="small"
          type="textarea"
          show-word-limit
          maxlength="400"
          v-model="reqBody.descricao"
        />
      </div>
      <div class="margin">
        <i
          class="fas fa-users"
          style="margin-right: 10px"
        />
        <label>Membros <b style="color: red">*</b></label>
      </div>
      <vue-perfect-scrollbar style="max-height: 300px; height: 300px">
        <el-checkbox
          v-model="checkAllUsuarios"
          @change="handleCheckAllChange"
        >
          Selecionar todos
        </el-checkbox>
        <el-checkbox-group v-model="usuariosSelecionados">
          <el-checkbox
            v-for="(c, i) in clientes"
            :label="c"
            :key="i"
          >
            <el-avatar icon="el-icon-user-solid" />
            <div class="cont-clientes">
              <div class="">
                <p class="usuario_info">{{ c.nome.toLowerCase() }}</p>
                <span
                  class="usuario_info"
                  style="text-transform: lowercase"
                >{{ c.email }}</span>
              </div>
            </div>
          </el-checkbox>
        </el-checkbox-group>
      </vue-perfect-scrollbar>
      <el-button
        size="small"
        class="bt-salvar"
        @click="criarGrupo"
        :disabled="reqBody.nome == null || usuariosSelecionados.length < 1"
        :loading="loading"
      >
        C R I A R
      </el-button>
    </div>
  </el-dialog>
</template>

<script>
import "../css/theme.css";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import KonnectService from "../services/KonnectEnterpriseService";

export default {
  name: "NovoGrupo",
  props: { visible: Boolean },
  components: { VuePerfectScrollbar },

  data() {
    return {
      loading: false,
      clientes: [],
      checkAllUsuarios: false,
      usuariosSelecionados: [],

      paginacao: {
        limit: 10,
        offset: 0,
        ffield: "id",
        order: "ASC",
        usaPaginacao: false,
        filterFields: "cpf,email",
        filterValue: "",
      },

      reqBody: {
        nome: null,
        descricao: "",
        outrosMembrosIds: [],
      },
    };
  },

  mounted() {
    this.listarClientes();
  },

  methods: {
    handleClose() {
      this.$emit("closeModal", 1);
    },

    listarClientes() {
      this.loading = true;
      KonnectService.getClientes(this.paginacao)
        .then((r) => (this.clientes = r.data))
        .catch((e) => console.log(e))
        .finally(() => (this.loading = false));
    },

    handleCheckAllChange(val) {
      this.usuariosSelecionados = val ? this.clientes : [];
    },

    criarGrupo() {
      this.loading = true;
      this.reqBody.outrosMembrosIds = [];
      this.usuariosSelecionados.forEach((u) =>
        this.reqBody.outrosMembrosIds.push(u.id)
      );

      KonnectService.postCanal("grupos", this.reqBody)
        .then((r) => {
          this.handleClose();
          this.$emit("abrirChat", r.data);
          this.$message({
            title: "Sucesso!",
            message: `Grupo "${r.data.nome}" criado.`,
            type: "success",
          });
        })
        .catch((e) => {
          this.$notify({
            title: "Erro ao criar grupo",
            message: e.response.headers["x-reason"],
            type: "warning",
          });
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
>>> p,
>>> label,
>>> span {
  font-weight: 400;
}

.usuario_info {
  margin-left: 10px;
}

>>> .el-dialog {
  margin: 0 auto 50px !important;
}

>>> .main-box {
  text-align: start;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}
>>> .margin {
  margin-top: 25px;
}

>>> .fa-users {
  color: var(--segunda-cor);
}

>>> .bt-salvar {
  background-color: var(--segunda-cor) !important;
  color: #fff !important;
}
/* ELEMENT UI */
>>> .el-dialog {
  margin-top: 5vh !important;
  width: 90% !important;
}
>>> .el-checkbox__label {
  display: flex !important;
  align-items: center !important;
}

>>> .el-checkbox {
  display: flex !important;
  align-items: center !important;
  padding: 10px 0 !important;
  margin: 0 !important;
}
</style>
