<template>
  <el-container v-if="!showInfo">
    <div class="top-bar" />
    <el-header>
      <div class="chat-header">
        <el-button
          icon="el-icon-arrow-left ar"
          @click="voltar"
        />
        <span>Mídias e Documentos</span>
      </div>
    </el-header>
    <el-divider />
    <el-main>
      <scroll
        v-loading="loading"
        v-if="loading || arquivos.length > 0"
        element-loading-text="Carregando mídias"
        style="height: 87vh"
        @ps-y-reach-end="getMidias"
        :wheelSpeed="0.5"
      >
        <div
          class="box-info-file"
          v-for="(a, i) in arquivos"
          :key="i"
        >
          <el-dropdown trigger="click" @command="(c) => handleCommand(c, a)">
            <el-button class="item-file">
              <div class="item-file__text">
                <i :class="
                    a.subtipoMensagem == 'image'
                      ? 'fas fa-image s'
                      : 'fas fa-file-alt s'
                  " />
                <span>{{ a.metadata.nome }}</span>
              </div>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="1">
                <i class="el-icon-download xd" />
                Baixar
              </el-dropdown-item>
              <el-dropdown-item command="2">
                <i class="fas fa-ellipsis-v xd x" />
                Informações
              </el-dropdown-item>
              <el-dropdown-item command="3">
                <i class="fas fa-comment-alt xd"></i>
                Ver no chat
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </scroll>
    </el-main>
  </el-container>
  <el-container v-else>
    <div class="top-bar" />
    <el-header>
      <div class="chat-header">
        <el-button
          icon="el-icon-arrow-left ar"
          @click="showInfo = false"
        />
        <span>Detalhes do arquivo</span>
      </div>
    </el-header>
    <el-divider />
    <el-main style="padding: 0 15px !important">
      <div class="box-infos">
        <i :class="
            arquivoSelecionado.subtipoMensagem == 'image'
              ? 'fas fa-image b'
              : 'fas fa-file-alt b'
          " />
        <span>{{ arquivoSelecionado.metadata.nome }}</span>
      </div>
      <div class="info-enviado">
        <p>
          Enviado {{ tratarDados(1) }} por <b>{{ tratarDados(2) }}</b>
        </p>
      </div>
      <!-- <el-divider />
      <div class="titulo-usuarios">
        <i class="el-icon-check" />
        <p>
          Visualizado por {{ arquivoSelecionado.length }} membros
        </p>
      </div>
      <div class="box-canal bd" v-for="(u, i) in usuarios" :key="i">
        <div style="display: flex; align-items: center">
          <el-avatar icon="el-icon-user-solid" />
          <span> {{ u.nome }} </span>
        </div>
      </div> -->

      <el-divider />
      <div class="titulo-usuarios">
        <i class="el-icon-download" />
        <p>Baixado por {{ arquivoSelecionado.baixadoPor.length }} membros</p>
      </div>
      <div
        class="box-canal bd"
        v-for="(u, i) in arquivoSelecionado.baixadoPor"
        :key="i"
      >
        <div class="baixado-por">
          <el-avatar icon="el-icon-user-solid" />
          <div class="baixado-por-info">
            <span class="baixado-por-nome"> {{ u.nome }} </span>
            <span><i class="fas fa-clock"></i> <b>Baixado em:</b>
              {{ tratarDataDownload(u.dataDeDownload) }}
            </span>
          </div>
        </div>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import "../css/theme.css";
import KonnectService from "../services/KonnectEnterpriseService.js";
import Scroll from "vue-perfect-scrollbar";
import moment from "moment";

import axios from "axios";
import download from "js-file-download";

export default {
  name: "Midias",
  components: { Scroll },
  props: { data: Object },

  data() {
    return {
      loading: false,
      showInfo: false,
      arquivos: [],
      arquivoSelecionado: [],

      // Variáveis de controle para a requisição
      pkIndex: null,
      isFinalDaLista: false,
      ultimoArquivoEncontrado: null,
      isExecutandoRequisicao: false,
    };
  },

  async created() {
    await this.getMidias();
  },

  methods: {
    voltar() {
      this.$emit("voltar");
    },

    async getMidias() {
      // Se estiver executando uma requisição ou se já finalizou, só retorna
      if (this.isExecutandoRequisicao || this.isFinalDaLista) return;

      // Indica que a requisição está sendo executada
      this.isExecutandoRequisicao = true;

      // Mostra o loading
      this.loading = true;

      // Tenta...
      let responseStatus;
      try {
        // Atualizar o valor da variável, se necessário
        if (!this.pkIndex) this.pkIndex = this.data.currentMessagesPkIndex;

        // Faz a requisição
        const r = await KonnectService.mensagensPkGetFiles(
          this.data._id,
          this.pkIndex,
          this.ultimoArquivoEncontrado?._id
        );

        // Se retornou algum valor...
        if (r.status === 200) {
          /** @type {Array} */
          const d = r.data;

          // Recupera o ultimo valor da lista. Ele será o "ultimoArquivoEncontrado"
          this.ultimoArquivoEncontrado = d[d.length - 1];

          // Atualiza a lista de arquivos
          this.arquivos.push(...d);

          // Garante que o isFinalDaLista está false
          this.isFinalDaLista = false;
        }

        // Guarda o status na variavel
        responseStatus = r.status;
      } catch (e) {
        console.error(e);
        this.isFinalDaLista = true;
      } finally {
        this.loading = false;
        this.isExecutandoRequisicao = false;
      }

      // Se retornou vazio...
      if (responseStatus === 204) {
        // Diminui um indice da pk
        this.pkIndex = this.pkIndex - 1;

        // Limpa o ultimo arquivo encontrado
        this.ultimoArquivoEncontrado = null;

        // Se a pk for 0, então finalizou toda a busca
        if (this.pkIndex <= 0) {
          this.isFinalDaLista = true;
        }

        // Tenta fazer a requisição
        await this.getMidias();
      }
    },

    tratarDados(v) {
      let a = this.arquivoSelecionado,
        data = moment(a.dataDeEnvio).format("DD/MM/YYYY"),
        hora = moment(a.dataDeEnvio).format("HH:mm"),
        por = this.data.membros.filter((u) => u.id == a.remetenteId);
      return v == 1 ? `${data} às ${hora}` : `${por[0].nome}`;
    },

    tratarDataDownload(data) {
      return `${moment(data).format("DD/MM/YYYY")} às ${moment(data).format(
        "HH:mm:ss"
      )}`;
    },

    handleCommand(c, a) {
      this.arquivoSelecionado = a;
      switch (c) {
        case "1":
          KonnectService.mensagensGetFileUriByPkAndId(
            this.data._id,
            a.pk,
            a._id
          ).then((r) => {
            axios
              .get(r.url, { responseType: "blob" })
              .then((r) => download(r.data, a.metadata.nome))
              .catch((e) =>
                this.$notify({
                  title: "Erro ao baixar arquivo.",
                  message: e?.response?.headers["x-reason"],
                  type: "warning",
                })
              )
              .finally(() => (this.loading = false));
          });
          break;
        case "2":
          this.showInfo = true;
          break;
        case "3":
          this.voltar();
          this.$emit("abrirEmMensagemEspecifica", a);
          break;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.el-main {
  padding: 10px 0 !important;
  width: 100%;
}

.box-info-file {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

>>> .el-dropdown {
  width: 100%;
  justify-content: center;
  display: flex;
}

.item-file,
.item-file:focus {
  padding: 15px;
  box-shadow: 2px 2px 2px #dddddd;
  margin: 10px;
  border: 1px solid #dddddd !important;
  border-radius: 5px;
  display: flex;
  width: 93%;
  align-self: center;
}

.item-file__text {
  display: flex;
  align-items: center;
  width: 100%;
}

.item-file__text span {
  text-overflow: ellipsis;
  min-height: 20px;
  overflow: hidden;
  align-items: center;
  display: flex;
}

.s {
  color: #a4a4a4;
  font-size: 25px;
  margin-right: 20px;
}

.b {
  color: #a4a4a4;
  font-size: 50px;
  margin-right: 20px;
}

.xd {
  color: var(--segunda-cor);
  font-size: 17px;
  font-weight: 600;
}

.x {
  margin: 0 11px 0 5px;
}

.el-button:focus,
.el-button:hover {
  color: #606266 !important;
  background-color: #d8fefe !important;
  border-color: var(--segunda-cor) !important;
}

.header-details {
  display: flex;
  margin: 10px 0;
  align-items: center;
  height: 50px;
}

.header-details h4 {
  font-weight: 600;
}

.box-infos {
  display: flex;
  align-items: center;
  margin: 20px;
}

.box-infos span {
  overflow-wrap: anywhere;
}

.info-enviado {
  margin: 20px;
  text-align: initial;
}

.info-enviado p {
  font-size: 14px;
  color: #656565;
}
.titulo-usuarios {
  display: flex;
  margin: 15px;
  align-items: center;
}
.el-icon-check,
.el-icon-download {
  color: var(--segunda-cor);
  margin-right: 10px;
  font-weight: 600;
}

.bd {
  border-bottom: 0.5px solid #ececec;
}

.baixado-por {
  display: flex;
  align-items: center;
}

.baixado-por-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.baixado-por span {
  font-size: 12px;
}

.baixado-por .baixado-por-nome {
  font-size: 16px;
  font-weight: bold;
}
</style>
