<template>
    <div id="audio-player-root" >

        <!-- Esconde o Audio player padrão -->
        <div >
            <audio
                style="display:none"
                ref="player"
                :id="playerid"
            >
                <source :src="url" type="audio/mpeg"  />
            </audio>
        </div>
            
            
        <div id="player-row">
            <div id="button-div">
                <svg
                    @click="toggleAudio()"
                    v-show="!isPlaying"
                    class="play-button"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                        clip-rule="evenodd"
                    />
                </svg>
                <svg
                    @click="toggleAudio()"
                    v-show="isPlaying"
                    class="stop-button"                            
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clip-rule="evenodd"
                    />
                </svg>
            </div>

            <div
                id="progress-bar"
            >
                <div class="overlay-container">
                    <div v-show="!audioLoaded"
                    class="loadingWarning"
                    style="color: #94bcec">
                    Carregando...
                    </div>
                    
                    <div
                        v-show="audioLoaded"
                        class="audioTiming"
                    >
                        <span class="text-sm" style="color: #94bcec" v-html="elapsedTime()"> 00:00 </span>
                        <span class="text-sm" style="color: #94bcec" v-html="totalTime()"> 00:00 </span>
                        
                    </div>
                </div>   
                    <canvas :id="canvasId" style="z-index: 1; position: relative;"></canvas>
                    <input
                        v-model="playbackTime"
                        type="range"
                        min="0"
                        :max="audioDuration"
                        class="slider"
                        id="position"
                        name="position"
                        ref="position" 
                    />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["url", "playerid"],
    /**
     * documention : https://muhammadatt.medium.com/building-an-mp3-audio-player-in-vue-js-c5884207251c
     * playbackTime = variável local que sincroniza o com audio.currentTime
     * audioDuration = duração do audio em segundos
     * isPlaying = booleano (true se o audio estiver tocando)
     *
     **/
    data() {
        return {
            playbackTime: 0,
            audioDuration: 100,
            audioLoaded: false,
            isPlaying: false,
            canvasId: `waveform-canvas-${this.playerid}`,
        };
    },
    methods: {
        //Seta o range slider max value para ficar igual a duração do áudio
        initSlider() {
            var audio = this.$refs.player;
            if (audio) {
                this.audioDuration = Math.round(audio.duration);
            }
        },
        //Converte o audio current time de segundo para minutos:segundos
        convertTime(seconds){
                            const format = val => `0${Math.floor(val)}`.slice(-2);
                var minutes = (seconds % 3600) / 60;
                return [minutes, seconds % 60].map(format).join(":");
        },
        // Mostra a duração total do audio
        totalTime() {
            var audio = this.$refs.player;
            if (audio) {
                var seconds = audio.duration;
                return this.convertTime(seconds);
            } else {
                return '00:00';
            }
        },
        // Mostra o tempo decorrido do áudio
        elapsedTime() {
            var audio = this.$refs.player;
            if (audio) {
                var seconds = audio.currentTime;
                return this.convertTime(seconds);
            } else {
                return '00:00';
            }
        },
        // Playback listener roda a cada 100ms enquanto o áudio está tocando
        playbackListener(e) {
            var audio = this.$refs.player;

            cancelAnimationFrame(this.playbackAnimationFrame);

            // Usar requestAnimationFrame para uma sincronização mais precisa
            const updatePlaybackTime = () => {
                this.playbackTime = audio.currentTime;

                if (audio.currentTime < this.audioDuration) {
                    this.playbackAnimationFrame = requestAnimationFrame(updatePlaybackTime);
                }
            };

            this.playbackAnimationFrame = requestAnimationFrame(updatePlaybackTime);
                
            //adiciona listeners para os eventos audio pause e audio ended 
            audio.addEventListener("ended", this.endListener);
            audio.addEventListener("pause", this.pauseListener);
        },
        pauseListener() {
            this.isPlaying = false;
            this.listenerActive = false;
            this.cleanupListeners();
        },
        endListener() {
            this.isPlaying = false;
            this.listenerActive = false;
            this.cleanupListeners();
        },
        cleanupListeners() {
            var audio = this.$refs.player;
            audio.removeEventListener("timeupdate", this.playbackListener);
            audio.removeEventListener("ended", this.endListener);
            audio.removeEventListener("pause", this.pauseListener);
        },
        toggleAudio() {
            var audio = this.$refs.player;
            if (audio.paused) {
                audio.play();
                this.isPlaying = true;
            } else {
                audio.pause();
                this.isPlaying = false;
            }
        },

        drawWaveform(){
            // Documentation: https://css-tricks.com/making-an-audio-waveform-visualizer-with-vanilla-javascript/
            // Configura contexto do áudio
            window.AudioContext = window.AudioContext || window.webkitAudioContext;
            const audioContext = new AudioContext();

            /**
             * Recupera áudio de uma fonte externa e inicializa a função de draw
             * @param {String} url A URL do áudio 
             */
            const drawAudio = url => {
            fetch(url)
                .then(response => response.arrayBuffer())
                .then(arrayBuffer => audioContext.decodeAudioData(arrayBuffer))
                .then(audioBuffer => draw(normalizeData(filterData(audioBuffer))));
            };

            /**
             * Filtra o AudioBuffer recuperado de uma fonte externa
             * @param {AudioBuffer} audioBuffer o AudioBuffer do drawAudio()
             * @returns {Array} um array de números de ponto flutuante
             */
            const filterData = audioBuffer => {
            const rawData = audioBuffer.getChannelData(0); 
            const samples = 70; // Número de amostras que queremos ter em nosso conjunto de dados final
            const blockSize = Math.floor(rawData.length / samples); // O número de amostras em cada subdivisão
            const filteredData = [];
            for (let i = 0; i < samples; i++) {
                let blockStart = blockSize * i; // A localização da primeira amostra no bloco
                let sum = 0;
                for (let j = 0; j < blockSize; j++) {
                sum = sum + Math.abs(rawData[blockStart + j]); // Encontra a soma de todas as amostras no bloco
                }
                filteredData.push(sum / blockSize); // divide a soma pelo tamanho do bloco para obter a média
            }
            return filteredData;
            };

            /**
             * Normaliza os dados de áudio para fazer uma ilustração mais limpa
             * @param {Array} filteredData o dados do filterData()
             * @returns {Array} Um array normalizado de números de ponto flutuante
             */
            const normalizeData = filteredData => {
                const multiplier = Math.pow(Math.max(...filteredData), -1);
                return filteredData.map(n => n * multiplier);
            }


            /**
             * Desenha o arquivo de áudio em um elemento canvas.
             * @param {Array} normalizedData O Array filtrado pelo filterData()
             * @returns {Array} Um array normalizado dos dados
             */
            const draw = normalizedData => {
                
                // Configurando o canvas
                const canvas = document.getElementById(this.canvasId);
                const dpr = 1;
                const padding = 20;
                console.log(canvas.offsetHeight)
                canvas.width = canvas.offsetWidth * dpr;
                canvas.height = (canvas.offsetHeight + padding * 0.5) * dpr;
                const ctx = canvas.getContext("2d");
                ctx.scale(dpr, dpr);
                ctx.translate(0, canvas.offsetHeight / 2 + padding); // Sete Y = 0 para ficar no meio do canvas

                // Desenha os segmentos de linha
                const width = canvas.offsetWidth / normalizedData.length;
                for (let i = 0; i < normalizedData.length; i++) {
                    const x = width * i;
                    let height = normalizedData[i] * canvas.offsetHeight - padding;
                    if (height < 0) {
                        height = 0;
                    } else if (height > canvas.offsetHeight / 2.5) {
                        height = height > canvas.offsetHeight / 2.5;
                    }
                    drawLineSegment(ctx, x, height, width, (i + 1) % 2);
                }
            };

            /**
             * Uma função  de utilidade para desenhar nossos segmentos de linha
             * @param {AudioContext} ctx O contexto de áudio 
             * @param {number} x  A coordenada x do início do segmento de linha
             * @param {number} height A altura desejada do segmento de linha
             * @param {number} width A largura desejada do segmento de linha
             * @param {boolean} isEven Se o segmentado é ou não par
             */
            const drawLineSegment = (ctx, x, height, width, isEven) => {
            ctx.lineWidth = 2; // Espessura da linha
            ctx.strokeStyle = getComputedStyle(document.documentElement).getPropertyValue('--segunda-cor'); // Cor da linha
            ctx.beginPath();
            height = isEven ? height : -height;
            ctx.moveTo(x, 0);
            ctx.lineTo(x, height);
            ctx.arc(x + width / 2, height, width / 2, Math.PI, 0, isEven);
            ctx.lineTo(x + width, 0);
            ctx.stroke();
            };

        
            drawAudio(this.url);
        },
    },
    mounted(){
        // Roda após a view ser renderizada
        this.$nextTick(function() {
            
                this.drawWaveform();

                var audio=this.$refs.player;

                
                // Espera o audio ser carregado, para rodar o initSlider e pegar a duração do audio e setar o valor maximo do Slider
                // "loademetadata" Event https://www.w3schools.com/tags/av_event_loadedmetadata.asp
                audio.addEventListener(
                "loadedmetadata",
                function(e) {
                    this.initSlider();
                }.bind(this)
                );
                // "canplay" HTML Event faz a gente saber se o áudio foi carregado https://www.w3schools.com/tags/av_event_canplay.asp
                audio.addEventListener(
                "canplay",
                function(e) {
                    this.audioLoaded=true;
                }.bind(this)
                );
                // Espera o áudio ser tocado para, então iniciar a função playbackListener
                this.$watch("isPlaying",function() {
                if(this.isPlaying) {
                    this.initSlider();
                    // Previne de iniciar multiplos listeners ao mesmo tempo
                    if(!this.listenerActive) {
                        this.listenerActive=true;
                    
                        this.playbackListener();
                    }
                }
                });
                // Atualiza a posição atual do audio quando o usuário move o slider
                this.$watch("playbackTime",function() {
                    var diff=Math.abs(this.playbackTime-this.$refs.player.currentTime);
                
                    // Acelera a sincronização para evitar loop infinito entre o playbackListener e ester watcher
                    if(diff>0.01) {
                        this.$refs.player.currentTime=this.playbackTime;
                        this.$refs.position.value = this.playbackTime;
                }
                });
            }
        );

        
    },
    
};
</script>
<style scoped>
#player-row{
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
}

#button-div{
    flex: 0 1 auto;
    padding-right: 0.75rem;
}


/* Play/Pause Button */
.play-button{
    margin-top: 70px;
    height: 45px;
    cursor: pointer;
    color: var(--segunda-cor-highlight);
}

.play-button :hover{
    color: var(--segunda-cor);
}

.stop-button{
    margin-top: 70px;
    height: 45px;
    cursor: pointer;
    color: var(--primeira-cor-highlight);
}

.stop-button :hover{
    color: var(--primeira-cor);
}

/* progress-bar */
#progress-bar{
    width: 270px;
    height: 139px;
    margin-bottom: 20px;
}

input[type="range"] {
    -webkit-appearance: none;
    margin: auto;
    position: relative;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
    outline: none;
    border-radius: 7px;
    background: transparent;
    pointer-events: auto;
}
input[type="range"]:focus {
    outline: none;
}
::-webkit-slider-runnable-track {
    background: var(--primeira-cor-claro);
    height: 138px;
}

::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 0; 
    height: 138px;
    background: var(--primeira-cor-claro);
    box-shadow: -100vw 0 0 100vw var(--primeira-cor-highlight); 
    border: none; 
    z-index: 1;
}
::-moz-range-track {
    height: 138px;
    background: var(--primeira-cor-claro);
}
::-moz-range-thumb {
    background: var(--primeira-cor-claro);
    height: 138px;
    width: 0; 
    border: none; 
    border-radius: 0 !important;
    box-shadow: -100vw 0 0 100vw var(--primeira-cor-highlight); 
    box-sizing: border-box;
    z-index: 1;
}
::-ms-fill-lower {
    background: var(--primeira-cor-highlight);
}
::-ms-thumb {
    background: var(--primeira-cor-claro);
    border: 2px solid #999;
    height: 5px;
    width: 20px;
    box-sizing: border-box;
}
::-ms-ticks-after {
    display: none;
}
::-ms-ticks-before {
    display: none;
}
::-ms-track {
    background: var(--primeira-cor-claro);
    color: transparent;
    height: 10px;
    border: none;
}
::-ms-tooltip {
    display: none;
}
.slider{
    height: 140px;
    top: -138px !important;
    z-index: 0;
    
}

.overlay-container{
    width: 100%;
    
}

.loadingWarning{
    width: 100%;
    padding-left: 0.5rem; /* 8px */
    padding-right: 0.5rem; /* 8px */
    pointer-events: none;
}

.audioTiming{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-left: 0.5rem; /* 8px */
    padding-right: 0.5rem; /* 8px */
    pointer-events: none;

}
canvas {
  margin: 2px auto;    
  height: 135px;
  pointer-events: none;
  display: block;
}
</style>