<template> 
    <div class="telaInicial">
        <el-header ref="inicioHeader">
        </el-header>
        <div class="logo">
            <img
                class="logo-img"
                src="../assets/images/konnect/konnect-logo--cinza.svg"
                alt="Logo do Konnect"
            />
        </div>
        <div class="texto">
            <h3>Bem Vindo ao Konnect</h3>
            <br/>
            <p>Aqui você pode conversar com seu colaboradores pelo chat, criar grupos e marcar e ver eventos pelo calendário.</p>
        </div>
        <div class="botoes">
            <el-button icon="fas fa-calendar-alt c icon-right" class="btCalendario" @click="$router.push({name: 'Calendario'})">Calendário</el-button> 

        <el-dropdown
          v-if="isRole('ROLE_ORGANIZACAO')"
          trigger="click"
          @command="criarCanal"
        >
          <el-button icon="fas fa-comment-alt icon-right">Novo Chat</el-button>

          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item :command="0">Nova Conversa</el-dropdown-item>
            <el-dropdown-item :command="1">Novo Grupo</el-dropdown-item>
            <el-dropdown-item :command="2">Novo Evento</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>

        <el-button v-else icon="fas fa-comment-alt icon-right" @click="criarCanal(0)">Nova Conversa</el-button>  
            
            <a class="btVoltar" :href="`${recursos.HOME.webapp.baseUrl}${recursos.HOME.rota}`"><el-button type="primary" circle><i class="fas fa-undo-alt"></i></el-button></a>
        </div>
       <nova-mensagem
            v-if="showModalPrivado"
            :visible="showModalPrivado"
            @closeModal="closeModal"
            @abrirChat="abrirChat"
        />
        <novo-grupo
            v-if="showModalGrupo"
            :visible="showModalGrupo"
            @closeModal="closeModal"
            @abrirChat="abrirChat"
        />
        <novo-evento
            v-if="showModalEvento"
            :visible="showModalEvento"
            @closeModal="closeModal"
            @abrirChat="abrirChat"
        />
    </div>
    
</template>

<script>
import "../css/theme.css";
import recursosKlausConfig, { recursos } from "@/assets/recursosKlausConfig";
import NovaMensagem from "./NovaMensagem.vue";
import NovoGrupo from "./NovoGrupo.vue";
import NovoEvento from "./NovoEvento.vue";
import AuthService from "../services/AuthService";
import AuthUtils from "../utils/AuthUtils.js";
export default {
    components: {NovaMensagem, NovoGrupo, NovoEvento },
    data(){
        return{
            recursos,
            showModalPrivado: false,
            showModalGrupo: false,
            showModalEvento: false,
            isRole: AuthUtils.isRole,
        };
    },
    mounted() {
        let data = this.$route.params.data;
        if (data) this.abrirChat(data);
        if(this.isMobile()){
            this.$router.push({name: "Mensagens"});
        }
    },

    methods:{
        abrirChat(c) {
            // Se o chat já estiver seleciona, só retorna
            if (this.$store?.getters?.chatAtual?._id === c._id) return;

            this.$nextTick(() => {
                this.$router.push({ name: "Chat" });
                this.$store.dispatch("SET_CHAT", c);
                this.chatSelecionado = c;
                c.notificacoes = 0;
            });
        },
        criarCanal(val) {
            if (val == 0) this.showModalPrivado = true;
            else if (val == 1) this.showModalGrupo = true;
            else this.showModalEvento = true;
        },
        closeModal(val) {
            if (val == 0) this.showModalPrivado = false;
            else if (val == 1) this.showModalGrupo = false;
            else this.showModalEvento = false;
        },
        //Veririfica o tamanho da tela
        isMobile() {
                if( window.innerWidth <= 800 || window.innerheight <= 800) {
                    return true;
                }
                else {
                    return false;
                }
            }      
    },
    beforeDestroy() {
      this.$store.dispatch("SET_CHAT", null);
    },
}
</script>

<style scoped>

.telaInicial{
    width: 100%;
    background-color: #fff;
}

.logo{
    margin-top: 10%;
    width: 100%;
    height: 100px;
}
.logo-img{
    width: 100px;
    height: 100px;
}
.texto{
    margin: 2% auto;
    width: 70%;
}
.botoes{
    margin-top: 2%;
}
.btVoltar{
    color: white;
    margin: 0px 15px 0px 15px;
    
}


.el-button{
    margin: 0px 15px 0px 15px;
    
}

/* .el-button--primary span{
   width: 100%;
    
}
.el-button--primary{
    background-color: #409EFF !important;
    margin: unset;
}
.el-button--primary:hover{
    background-color: #409EFF !important;
    color: #25d8c2 !important;
} */
</style>