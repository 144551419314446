<template>
  <div class="root">
    <side-bar
      :chatAtivo="chatAtivo"
      :dividirTela="dividirTela"
      :header="'Chat'"
    >
      <template v-slot:side-menu>
        <el-input
          placeholder="Pesquisar"
          prefix-icon="el-icon-search"
          v-model="filtroCanais"
          clearable
          size="small"
          style="margin-bottom: 15px"
          @input="filtrarCanais"
        />
          <!-- Componente de Loading dos Canais -->
          <div class="wrapper-canais" v-loading="loadingCanais">  
            <div class="canais">
              <div v-for="(c, i) in canaisFiltrados" :key="i">
                <div
                  :class="`box-canal ${
                    !$store.getters.chatAtual ||
                    $store.getters.chatAtual._id !== c._id
                      ? ''
                      : 'box-canal-selected'
                  }`"
                  @click="abrirChat(c)"
                >
                  <div style="display: flex; align-items: center; text-align: left" v-if="dataReady">
                    <el-avatar class="circle-icon" v-if="c.tipo == 'PRIVADO'" icon="fas fa-user p"/>
                    <el-avatar class="circle-icon" v-else-if="c.tipo == 'GRUPO'" icon="fas fa-users g" :src="`${kmsx}canais-cover-organizacao-${c.organizacaoId}/${c._id}-cover-80.png?${token}`"/>
                    <el-avatar class="circle-icon" v-else icon="fas fa-calendar-alt g" :src="`${kmsx}canais-cover-organizacao-${c.organizacaoId}/${c._id}-cover-80.png?${token}`"/>
                    <span class="nomeCanal" style="margin-left: 20px">
                      {{ nomeCanal(c) }}
                    </span>
                  </div>
                  <div class="box-notificacao" v-if="c.notificacoes > 0">
                    <span>{{ c.notificacoes }}</span>
                  </div>
                  <div v-if="c.tipo == 'EVENTO' && c.status">
                    <!-- <i class="el-icon-success sc" v-if="canal.status == 'concluido'" />
                  <i class="el-icon-warning wr" v-if="canal.status == 'expirado'" /> -->
                    <i
                      :class="c.status ? 'el-icon-warning' : 'el-icon-success'"
                      :style="c.status ? 'color: #f56c6c' : 'color: #67C23A'"
                    />
                  </div>
                </div>
                <el-divider />
              </div>
            </div>
          </div>
        <div class="btn-wrapper">
          <el-dropdown
            v-if="isRole('ROLE_ORGANIZACAO')"
            trigger="click"
            @command="criarCanal"
          >
            <el-button circle class="bt-nova-canal" icon="el-icon-plus pl" />

            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item :command="0">Nova Conversa</el-dropdown-item>
              <el-dropdown-item :command="1">Novo Grupo</el-dropdown-item>
              <el-dropdown-item :command="2">Novo Evento</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>

          <div v-else>
            <el-button
              circle
              icon="el-icon-plus pl"
              @click="criarCanal(0)"
              class="bt-nova-canal"
            />
          </div>
        </div>
      </template>
    </side-bar>

    <!-- Painel lateral -->
    <el-container v-if="chatAtivo">
      <router-view />
    </el-container>

    <nova-mensagem
      v-if="showModalPrivado"
      :visible="showModalPrivado"
      @closeModal="closeModal"
      @abrirChat="abrirChat"
    />
    <novo-grupo
      v-if="showModalGrupo"
      :visible="showModalGrupo"
      @closeModal="closeModal"
      @abrirChat="abrirChat"
    />
    <novo-evento
      v-if="showModalEvento"
      :visible="showModalEvento"
      @closeModal="closeModal"
      @abrirChat="abrirChat"
    />
  </div>
</template>
<script>
import "../css/theme.css";
import KonnectService from "../services/KonnectEnterpriseService.js";
import Chat from "./Chat.vue";
import AuthUtils from "../utils/AuthUtils.js";
import NovaMensagem from "./NovaMensagem.vue";
import NovoGrupo from "./NovoGrupo.vue";
import NovoEvento from "./NovoEvento.vue";
import KWDDPHelper from "../websockets/KWDDPHelper";
import eventBus from "../eventBus";
import axios from "axios";
import Vue from "vue";
import SideBar from "../components/UserBar/SideBar.vue";

export default {
  name: "Mensagens",
  props: {},
  components: {
    Chat,
    NovaMensagem,
    NovoGrupo,
    NovoEvento,
    SideBar,
  },

  data() {
    return {
      isRole: AuthUtils.isRole,

      dividirTela: false,
      canais: [],
      loadingCanais: true,
      canaisFiltrados: [],
      filtroCanais: "",
      chatSelecionado: [],
      showModalPrivado: false,
      showModalGrupo: false,
      showModalEvento: false,
      token:"",
      dataReady: false,
      kmsx: process.env.VUE_APP_KMSX_SERVICE_AZURE_STORAGE,
    };
  },

  computed: {
    nomeUsuarioLogado() {
      return JSON?.parse(window?.localStorage?.getItem("usuario"))?.nome;
    },
    chatAtivo() {
      return this.$route.name !== "Mensagens";
    },
  },

  async mounted() {
    

    window.addEventListener("keyup", (keyboardEvent) => {
      if (keyboardEvent.code === "Escape") this.fecharChat();
    });
    this.verificarSePodeDivirATela();
    window.addEventListener("resize", this.verificarSePodeDivirATela);

    let data = this.$route.params.data;
    if (data) this.abrirChat(data);

    if (
      this.$router.currentRoute.name == "Chat" &&
      this.chatSelecionado.length == 0
    ) {
      this.$router.push({ name: "Mensagens" });
    }

    if (!this.isMobile()) {
      this.$router.push({ name: "TelaInicial" });
    }

    KWDDPHelper.iniciarConexao();
    if (!window.localStorage.token) this.$router.push("/login");
    else await this.listarCanais();

    await KonnectService.getImagemsUri().then((r) => {
                  window.localStorage.setItem("TokenSAS", r.data)
                  this.token = window.localStorage.getItem("TokenSAS")
                }).catch((e) => {
                    if (e.response.status != 401) {
                          this.$notify({
                            title: "Erro ao Trazer as Imagens.",
                            message: e.response.headers["x-reason"],
                            type: "warning",
                          })
                      }
                  }
                    );
    this.dataReady = true;
    

    // Remove todos os listeners
    eventBus.$off("addNotificacao");
    eventBus.$off("novoCanal");
    eventBus.$off("alteradoCanal");
    eventBus.$off("deletarCanal");
    eventBus.$off("novoMembro");
    eventBus.$off("deletarMembro");
    eventBus.$off("classificarEventos");

    // Adiciona um listener de cada evento
    eventBus.$on("addNotificacao", (msg) => this.addNotificacao(msg));
    eventBus.$on("novoCanal", (canal) => this.novoCanal(canal, false));
    eventBus.$on("alteradoCanal", (canal) => this.alteradoCanal(canal, false));
    eventBus.$on("deletarCanal", (canal) => this.excluirCanal(canal));
    eventBus.$on("novoMembro", (data) => this.canalMembrosNovo(data));
    eventBus.$on("deletarMembro", (data) => this.canalMembrosDeletar(data));
    eventBus.$on("classificarEventos", (canal) => this.classificarEventos(canal));
    eventBus.$on("reordenarCanais", (canalId, dataDeEnvioUltimaMensagem) => this.isNecessarioReordenar(canalId) ? this.prepararParaOrdenarMensagens(canalId, dataDeEnvioUltimaMensagem) : false);
   
  },

  methods: {
    verificarSePodeDivirATela() {
      const deveDividir = window.innerWidth > 1000;
      if (this.dividirTela ^ deveDividir) this.dividirTela = deveDividir;
    },

    closeModal(val) {
      if (val == 0) this.showModalPrivado = false;
      else if (val == 1) this.showModalGrupo = false;
      else this.showModalEvento = false;
    },

    async listarCanais() {
      try{
        let tipos = ["grupos", "privados", "eventos"];
        // Percorrendo os Tipos de Canais existentes
        for(let tipo of tipos){
          var {data} = await KonnectService.getCanais(tipo);
          // Percorrendo os Canais existentes
          for(let canal of data){
            // Recupera a Ultima mensagem do Canal
            var {data} = await KonnectService.mensagensPkGetUltimas(canal._id, canal.currentMessagesPkIndex, 1);

            // Adiciona atributos de notificacoes e data da ultima mensagem ao canal, caso o canal nao tenha mensagens adiciona uma data muito antiga nele
            Object.assign(canal, { notificacoes: 0, dataUltimaMensagem: (data[0]?.dataDeEnvio ?  data[0]?.dataDeEnvio : new Date("1999-10-07"))});
            // Adicionamos o canal nas listas do componente 
            this.canais.push(canal);
            this.canaisFiltrados.push(canal);
          }
        }
        // Ordenamos as mensagens da mais recente para mais antiga
        await this.ordenarMensagens();
      }catch(error){
        console.log(error);
      }
    },

    prepararParaOrdenarMensagens(canalId, dataDeEnvioUltimaMensagem){
      // Recupera o chat do qual recebeu a mensagem mais recente e coloca a data do envio nele
      this.canais[this.canais.findIndex(canal => canal._id===canalId)].dataUltimaMensagem = dataDeEnvioUltimaMensagem;
      // Solicita a ordenacao das mensagens
      this.ordenarMensagens();
    },

    ordenarMensagens(){
      // Utiliza a funcao comparaData para ordenar ambas as listas de canais 
      this.canais = this.canais.sort(this.comparaData);
      this.canaisFiltrados = this.canaisFiltrados.sort(this.comparaData);
      this.loadingCanais = false;
    },

    comparaData(data1, data2){
      return new Date(data2.dataUltimaMensagem) - new Date(data1.dataUltimaMensagem); 
    },

    // Caso o canal seja o primeiro da lista nao sera necessario ordenar
    isNecessarioReordenar(canalId){
      return !(canalId === this.canais[0]._id);
    },

    filtrarCanais(val) {
      this.canaisFiltrados = this.canais.filter((u) =>
        u.nome.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "").includes(val.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""))
      );
    },

    addNotificacao(msg) {
      let usuarioLogado = JSON.parse(window.localStorage.getItem("usuario")).id;
      if (msg.remetenteId != usuarioLogado) {
        let chat = this.canais.find((c) => c._id == msg.canalId);
        chat.notificacoes += 1;
        this.$nextTick(() => this.$forceUpdate());
        let conteudo = msg.conteudo || msg.metadata.nome;
        console.log(msg.canalId);
        console.log(this.chatAtivo?._id);
        if (msg.canalId != this.chatAtivo?._id) {
          KonnectService.notificarNovaMsg(conteudo, chat.nome);
        }
        // Prepara nova ordenacao de mensagens
        this.prepararParaOrdenarMensagens(msg.canalId, new Date());
      }
    },

    classificarEventos(c) {
      let hoje = new Date().setHours(0, 0, 0, 0);
      let ativo = hoje <= new Date(c.dataDoEvento).setHours(0, 0, 0, 0);
      if (c.dataDeConclusao == null && !ativo)
        Object.assign(c, { status: "expirado" });
    },

    nomeCanal(c) {
      if (c.tipo == "PRIVADO") {
        let id = JSON.parse(window.localStorage.getItem("usuario")).id;
        let membro = c.membros.find((m) => m.id != id);
        Object.assign(c, { nome: membro.nome });
        return membro.nome;
      } else return c.nome;
    },

    novoCanal(c, abrir = true) {
      if (c.tipo == "EVENTO") this.classificarEventos(c);

      if (c.tipo !== "PRIVADO" || this.canais.every((x) => x?._id !== c._id))
        this.canais.push(c);
      if (
        c.tipo !== "PRIVADO" ||
        this.canaisFiltrados.every((x) => x?._id !== c._id)
      )
        this.canaisFiltrados.push(c);
      if (abrir) this.abrirChat(c);
    },

    alteradoCanal(c, abrir = true){
      KonnectService.getImagemsUri()
                .then((r) => {
                  window.localStorage.setItem("TokenSAS", r.data)
                  this.token = window.localStorage.getItem("TokenSAS")
                }).catch((e) =>
                      this.$notify({
                        title: "Erro ao Trazer as Imagens.",
                        message: e.response.headers["x-reason"],
                        type: "warning",
                      })
                    );
      this.$nextTick(() => this.$forceUpdate());
      if (c.tipo == "EVENTO") {
        let canal = this.canais.find((x) =>  x._id == c._id)
        canal.dataDeConclusao = c.dataDeConclusao
        this.classificarEventos(c);
      }
      if (c.tipo !== "PRIVADO"){
        let canal = this.canais.find((x) =>  x._id == c._id)
        canal.membros = c.membros
        canal.nome = c.nome
        canal.descricao = c.descricao
      }
      if (c.tipo !== "PRIVADO" 
      ){
        let canal =  this.canaisFiltrados.find((x) =>  x._id == c._id)
        canal.membros = c.membros
        canal.nome = c.nome
        canal.descricao = c.descricao
      }
      eventBus.$emit("alteradoMembro")
      if (abrir) this.abrirChat(c);
    },

    abrirChat(c) {
      // Se o chat já estiver seleciona, só retorna
      if (this.$store?.getters?.chatAtual?._id === c._id) return;

      this.fecharChat();
      this.$nextTick(() => {
        this.$router.push({ name: "Chat" });
        this.$store.dispatch("SET_CHAT", c);
        this.chatSelecionado = c;
        c.notificacoes = 0;
      });
    },

    fecharChat() {
      this.showModal = false;
      this.chatSelecionado = {};
      this.$store.dispatch("SET_CHAT", null);
      this.$router.push({ name: "Mensagens" }).catch(() => {});
      this.$forceUpdate();
    },

    criarCanal(val) {
      if (val == 0) this.showModalPrivado = true;
      else if (val == 1) this.showModalGrupo = true;
      else this.showModalEvento = true;
    },

    excluirCanal(c) {
      this.canaisFiltrados = this.canaisFiltrados.filter(
        (c2) => c2._id != c._id
      );
      this.canais = this.canais.filter((c2) => c2._id != c._id);
      // Verifica se o Chat atual cadastrado no VueXy eh o mesmo chat aberto pelo cliente 
      if(this.$store?.getters?.chatAtual?._id === c._id){
        // Se for o mesmo chat e o cliente nao for a organizacao exibe a mensagem de Chat apagado 
        if(AuthUtils.isRole('ROLE_ORGANIZACAO')){
          this.$message({
            title: "Grupo Excluido com Sucesso",
            message: "O grupo foi apagado com Sucesso!",
            type: "success"
          });
        }else{
          this.$message({
            title: "Grupo Excluido",
            message: "O Grupo em que você estava conectado foi Apagado ou Você foi removido!",
            type: "warning"
          });
        }
        this.fecharChat();
      }
    },

    canalMembrosNovo(data) {
      if(this.canais.find((x) => x._id === data.canal._id))
        this.alteradoCanal(data.canal, false);
      else this.novoCanal(data.canal, false);
      
      Vue.set(
        this.canais.find((x) => x._id === data.canal._id),
        "membros",
        data.todosOsMembrosDoCanal
      );
    },

    canalMembrosDeletar(data) {
      // Se o usuário logado for o membro que está sendo removido, fecha modais e já finaliza o método
      if (
        data.membroRemovidoId ===
        JSON.parse(window.localStorage.getItem("usuario")).id
      ) {
        this.excluirCanal(data.canal);
        this.closeModal();
        return;
      }

      // Remove membro
      const c = this.canais.find((x) => x._id === data.canal._id);
      Vue.set(
        c,
        "membros",
        c.membros.filter((x) => x.id !== data.membroRemovidoId)
      );
    },
    //Veririfica o tamanho da tela
    isMobile() {
      if (window.innerWidth <= 800 || window.innerheight <= 800) {
        return true;
      } else {
        return false;
      }
    },
  },
  beforeDestroy() {
      this.chatSelecionado = {};
      this.$store.dispatch("SET_CHAT", null);
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.main-panel {
  display: flex !important;
  flex-direction: column;
}

.btn-wrapper {
  position: absolute;
  bottom: 20px;
  right: 25px;
}

.root {
  display: flex !important;
  flex-direction: row;

  flex-grow: 60 !important;
  /* height: 80vh !important; */
}

.chat-container {
  min-width: 50vw;
}

.fullwidth {
  flex-grow: 1;
}
.halfwidth {
  /* cursor: col-resize; */
  resize: horizontal;
  overflow: auto;
  height: 100%;
  min-width: 405px;
}
.nomeCanal {
  overflow: hidden;
}

/* ELEMENT UI  */
>>> .el-main {
  padding: 12px !important;
}

>>> .el-button {
  border: none !important;
}

>>> .el-divider--horizontal {
  margin: 0 !important;
}

>>> .el-date-table >>> td.current:not(.disabled) >>> span {
  background-color: var(--segunda-cor) !important;
  color: #fff !important;
}

>>> .el-date-table >>> td >>> .today >>> span,
>>> .el-select-dropdown__item.selected {
  color: var(--segunda-cor) !important;
}

>>> .av {
  color: #0060b3;
}

>>> .el-header {
  padding: 0 !important;
}

>>> .header-chat span {
  font-weight: 600;
  font-size: 17px;
  color: #03283f;
}

>>> .fa-search {
  color: #c4c4c4;
  font-size: 13px;
}

>>> .p,
>>> .g,
>>> .e {
  color: #fff;
  font-size: 18px;
}

>>> .circle-icon {
  background-color: #e2e2e2;
  min-width: 50px;
  min-height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.box-canal {
  min-width: 300px;
}

.box-canal:hover {
  background-color: #f3f3f3;
}

>>> .box-canal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 5px;
  cursor: pointer;
}

>>> .box-canal-selected {
  background-color: #ebebeb;
  cursor: default;
}

>>> .box-canal span {
  color: #656565;
  font-size: 15px;
  text-overflow: ellipsis;
  max-width: 100%;
  line-height: 50px;
  white-space: nowrap;
  max-height: 50px;
}

>>> .box-notificacao {
  background: #53e20c;
  padding: 7px;
  border-radius: 10px;
  height: 10px;
  width: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

>>> .box-notificacao span {
  color: #fff;
  font-size: 12px;
}

>>> .bt-nova-canal {
  background-color: var(--segunda-cor);
  border-color: var(--segunda-cor) !important;
}

>>> .bt-nova-canal:hover {
  background-color: var(--segunda-cor-highlight) !important;
  border-color: var(--segunda-cor) !important;
}

>>> .bt-nova-canal i {
  font-size: 2em;
}
>>> .pl {
  color: #fff;
}

>>> .el-dropdown-menu__item,
>>> .el-notification__group {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
}

.canais {
  max-height: 60vh;
}

.wrapper-canais {
  height: 70vh;
  width: 100%;
}
</style>
