<template>
    <div id="audio-player-root" >

        <!-- Esconde o Audio player padrão -->
        <div >
            <audio
                style="display:none"
                ref="player"
                :id="playerid"
            >
                <source :src="url" type="audio/mpeg" />
            </audio>
        </div>
            
            
        <div id="player-row">
            <div id="button-div">
                <svg
                    @click="toggleAudio()"
                    v-show="!isPlaying"
                    class="play-button"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fill-rule="evenodd"
                        d="M10 18a8 8 0 100-16 8 8 0 000 16zM9.555 7.168A1 1 0 008 8v4a1 1 0 001.555.832l3-2a1 1 0 000-1.664l-3-2z"
                        clip-rule="evenodd"
                    />
                </svg>
                <svg
                    @click="toggleAudio()"
                    v-show="isPlaying"
                    class="stop-button"                            
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                >
                    <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zM7 8a1 1 0 012 0v4a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v4a1 1 0 102 0V8a1 1 0 00-1-1z"
                        clip-rule="evenodd"
                    />
                </svg>
            </div>

            <div
                id="progress-bar"
            >
                <div class="overlay-container">
                    <input
                        v-model="playbackTime"
                        type="range"
                        min="0"
                        :max="audioDuration"
                        class="slider"
                        id="position"
                        name="position"
                    />
                    <div v-show="!audioLoaded"
                    class="loadingWarning"
                    style="color: #94bcec">
                    Loading please wait...
                    </div>
                    
                    <div
                        v-show="audioLoaded"
                        class="audioTiming"
                    >
                        <span class="text-sm" style="color: #94bcec" v-html="elapsedTime()"> 00:00 </span>
                        <span class="text-sm" style="color: #94bcec" v-html="totalTime()"> 00:00 </span>
                        
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ["url", "playerid"],
    /**
     * documention : https://muhammadatt.medium.com/building-an-mp3-audio-player-in-vue-js-c5884207251c
     * playbackTime = variável local que sincroniza o com audio.currentTime
     * audioDuration = duração do audio em segundos
     * isPlaying = booleano (true se o audio estiver tocando)
     *
     **/
    data() {
        return {
            playbackTime: 0,
            audioDuration: 100,
            audioLoaded: false,
            isPlaying: false
        };
    },
    methods: {
        //Seta o range slider max value para ficar igual a duração do áudio
        initSlider() {
            var audio = this.$refs.player;
            if (audio) {
                this.audioDuration = Math.round(audio.duration);
            }
        },
        //Converte o audio current time de segundo para minutos:segundos
        convertTime(seconds){
                            const format = val => `0${Math.floor(val)}`.slice(-2);
                var hours = seconds / 3600;
                var minutes = (seconds % 3600) / 60;
                return [minutes, seconds % 60].map(format).join(":");
        },
        // Mostra a duração total do audio
        totalTime() {
            var audio = this.$refs.player;
            if (audio) {
                var seconds = audio.duration;
                return this.convertTime(seconds);
            } else {
                return '00:00';
            }
        },
        // Mostra o tempo decorrido do áudio
        elapsedTime() {
            var audio = this.$refs.player;
            if (audio) {
                var seconds = audio.currentTime;
                return this.convertTime(seconds);
            } else {
                return '00:00';
            }
        },
        // Playback listener roda a cada 100ms enquanto o áudio está tocando
        playbackListener(e) {
            var audio = this.$refs.player;
            
            // Sincroniza a variável local playbackTime com o audio.currentTime a atualiza o estado global
            this.playbackTime = audio.currentTime;
            
            //adiciona listeners para os eventos audio pause e audio ended
            audio.addEventListener("ended", this.endListener);
            audio.addEventListener("pause", this.pauseListener);
        },
        pauseListener() {
            this.isPlaying = false;
            this.listenerActive = false;
            this.cleanupListeners();
        },
        endListener() {
            this.isPlaying = false;
            this.listenerActive = false;
            this.cleanupListeners();
        },
        cleanupListeners() {
            var audio = this.$refs.player;
            audio.removeEventListener("timeupdate", this.playbackListener);
            audio.removeEventListener("ended", this.endListener);
            audio.removeEventListener("pause", this.pauseListener);
        },
        toggleAudio() {
            var audio = this.$refs.player;
            if (audio.paused) {
                audio.play();
                this.isPlaying = true;
            } else {
                audio.pause();
                this.isPlaying = false;
            }
        },
    },
    mounted: function() {
      // Roda após a view ser renderizada
      this.$nextTick(function() {
        
        var audio=this.$refs.player;
        // Espera o audio ser carregado, para rodar o initSlider e pegar a duração do audio e setar o valor maximo do Slider
        // "loademetadata" Event https://www.w3schools.com/tags/av_event_loadedmetadata.asp
        audio.addEventListener(
          "loadedmetadata",
          function(e) {
            this.initSlider();
          }.bind(this)
        );
        // "canplay" HTML Event Event faz a gente saber se o áudio foi carregado https://www.w3schools.com/tags/av_event_canplay.asp
        audio.addEventListener(
          "canplay",
          function(e) {
            this.audioLoaded=true;
          }.bind(this)
        );
        // Espera o áudio ser tocado para, então iniciar a função playbackListener
        this.$watch("isPlaying",function() {
          if(this.isPlaying) {
            var audio=this.$refs.player;
            this.initSlider();
            // Previne de iniciar multiplos listeners ao mesmo tempo
            if(!this.listenerActive) {
              this.listenerActive=true;
              audio.addEventListener("timeupdate",this.playbackListener);
            }
          }
        });
        // Atualiza a posição atual do audio quando o usuário move o slider
        this.$watch("playbackTime",function() {
        var diff=Math.abs(this.playbackTime-this.$refs.player.currentTime);
        
          // Acelera a sincronização para evitar loop infinito entre o playbackListener e ester watcher
          if(diff>0.01) {
            this.$refs.player.currentTime=this.playbackTime;
          }
        });
      });
    }
};
</script>
<style scoped>
#player-row{
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
}

#button-div{
    flex: 0 1 auto;
    padding-right: 0.75rem;
}


/* Play/Pause Button */
.play-button{
    margin-top: 2px;
    height: 45px;
    cursor: pointer;
    color: var(--segunda-cor-highlight);
}

.play-button :hover{
    color: var(--segunda-cor);
}

.stop-button{
    margin-top: 2px;
    height: 45px;
    cursor: pointer;
    color: var(--primeira-cor-highlight);
}

.stop-button :hover{
    color: var(--primeira-cor);
}

/* progress-bar */
#progress-bar{
    flex-grow: 1;
}

input[type="range"] {
    -webkit-appearance: none;
    margin: auto;
    position: relative;
    overflow: hidden;
    width: 100%;
    cursor: pointer;
    outline: none;
    border-radius: 0; /* iOS */
    background: transparent;
}
input[type="range"]:focus {
    outline: none;
}
::-webkit-slider-runnable-track {
    background: var(--primeira-cor-claro);
    height: 2px;
}

::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 0; 
    height: 2px;
    background: var(--primeira-cor-claro);
    box-shadow: -100vw 0 0 100vw var(--primeira-cor-highlight); 
    border: none; 
}
::-moz-range-track {
    height: 2px;
    background: var(--primeira-cor-claro);
}
::-moz-range-thumb {
    background: var(--primeira-cor-claro);
    height: 2px;
    width: 0; 
    border: none; 
    border-radius: 0 !important;
    box-shadow: -100vw 0 0 100vw var(--primeira-cor-highlight); 
    box-sizing: border-box;
}
::-ms-fill-lower {
    background: var(--primeira-cor-highlight);
}
::-ms-thumb {
    background: var(--primeira-cor-claro);
    border: 2px solid #999;
    height: 5px;
    width: 20px;
    box-sizing: border-box;
}
::-ms-ticks-after {
    display: none;
}
::-ms-ticks-before {
    display: none;
}
::-ms-track {
    background: var(--primeira-cor-claro);
    color: transparent;
    height: 10px;
    border: none;
}
::-ms-tooltip {
    display: none;
}
.slider{
    height: 2px;
}

.overlay-container{
    width: 100%;
    height: 100%;
}

.loadingWarning{
    width: 100%;
    padding-left: 0.5rem; /* 8px */
    padding-right: 0.5rem; /* 8px */
    pointer-events: none;
}

.audioTiming{
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-left: 0.5rem; /* 8px */
    padding-right: 0.5rem; /* 8px */
    pointer-events: none;

}
</style>