class Mensagem {
    constructor(conteudo, mensagemPai, tipoMensagem, remetente, canal, dataEnvio) {
        this._id = null;
        this.conteudo = conteudo;
        this.mensagemPai = mensagemPai;
        this.tipoMensagem = tipoMensagem;
        this.remetenteId = remetente;
        this.dataDeEnvio = dataEnvio;
        this.canalId = canal;
    }
}

export default Mensagem;