<template>
  <el-container>
    <el-dialog
      title="Upload Foto"
      :visible.sync="visible"
      :before-close="handleClose"
    >
      <div class="main-box">
        <vue-cropper
        ref="cropper"
        :guides="true"
        :view-mode="2"
        drag-mode="none"
        :auto-crop-area="0.5"
        :min-container-width="250"
        :min-container-height="180"
        :background="false"
        :rotatable="false"
        :src="imgSrc"
        alt="Source Image"
        :img-style="{ width: '400px', height: '300px' }"
        :aspect-ratio="1"
        preview=".preview"
        ></vue-cropper>
        <p>Prévia da foto de perfil:</p>
        <div class="preview" />
        <el-button class="cortarUpload" @click="cropImage" v-if="imgSrc != ''" >
          Cortar e Realizar Upload
        </el-button>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import FileUtils from "@/utils/FileUtils.js";
import "../css/theme.css";
import KonnectService from "../services/KonnectEnterpriseService";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  name: "UploadFoto",
  props: { visible: Boolean, fileSelected: File, data: Object },
  components: {VueCropper},

  data() {
    return {
      imageBase64: "",
      imgSrc: "",
      cropImg: "",

    };
  },

  mounted() {
    if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(this.fileSelected);
      } else {
        alert("Sorry, FileReader API not supported");
        this.$emit("close");
      }
  },
  methods: {
    handleClose() {
      this.cropImg = ""
      this.$emit("close");
    },

    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas({
          width:1024,
          imageSmoothingEnabled: true,
          imageSmoothingQuality: "high",
        })
        .toDataURL('image/png', 1);
      
      this.imageBase64 = this.cropImg.substring(
        this.cropImg.search(",") + 1,
        this.cropImg.length 
      );

      let reqBody = { imagem: this.imageBase64 };

      KonnectService.uploadImagem(this.data, reqBody)
      .then((r) => {
          this.$emit("alterar", r.data);
          this.$router.push({ name: "Chat" });
          this.$message({
            type: "success",
            message:
              "Imagem alterada com sucesso",
          });
        })
        .catch((e) =>
          this.$notify({
            title: "Erro ao alterar imagem!",
            message: e.response.headers["x-reason"],
            type: "warning",
          })
        );
      
    },

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
>>> .main-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

>>> .el-dialog {
  margin: 15vh auto 50px !important;
}

>>> .bt-salvar {
  background-color: var(--segunda-cor);
  color: #fff;
  margin-top: 30px !important;
}

.img-box{
  border-right: 1px solid #adadad;
  width: 50%;
  padding-bottom: 2%;
}

.image_upload {
  
  max-width: 430px;
  text-align: center;
  margin: auto;
  margin-top: 10px;
  display: flex;
}

>>> .avatar-uploader .el-upload {
  border: 1px dashed #acacac;
  margin-bottom: 10px;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

>>> .avatar-uploader .el-upload:hover {
  border-color: #25d8c2 !important;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

>>> .btnLimpar{
  background-color:#1e2b2f;
  color: #fff;
  margin-top: 5px !important;
  margin-bottom: 15px;
}
>>> .btnLimpar:focus{
  background-color: #1e2b2f !important;
  color: #fff !important;
  margin-top: 5px !important;
  margin-bottom: 15px;
}
.preview {
  width: 80px !important;
  height: 80px !important;
  overflow: hidden;
  text-align: center;
  border-radius: 100%;
}

>>> .cortarUpload{
  background-color:#1e2b2f;
  color: #fff;
  margin-top: 10px !important;
  margin-bottom: 15px;
}
>>> .cortarUpload:focus{
  background-color: #1e2b2f !important;
  color: #fff !important;
  margin-bottom: 15px;
}

</style>
