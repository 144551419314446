<template>
  <el-container id="chat-screen" ref="containerChat">
    <el-header ref="chatHeader">
      <div class="chat-header">
        <div class="chat-title">
          <el-button icon="el-icon-arrow-left ar" @click="voltar" />
          <el-avatar class="circle-icon" v-if="data.tipo == 'PRIVADO'" icon="fas fa-user p" />
          <el-avatar class="circle-icon" v-else-if="data.tipo == 'GRUPO'" icon="fas fa-users g"
            :src="`${kmsx}canais-cover-organizacao-${data.organizacaoId}/${data._id}-cover-80.png?${token}`" />
          <el-avatar class="circle-icon" v-else icon="fas fa-calendar-alt g"
            :src="`${kmsx}canais-cover-organizacao-${data.organizacaoId}/${data._id}-cover-80.png?${token}`" />
          <span @click="abrirInfo">{{ nomeCanal }}</span>
        </div>
        <input type="file" style="display: none" ref="fileInput" @change="onFilePicked" />
        <div style="margin-right: 20px" class="acoes">
          <i class="fas fa-paperclip" @click="onPickFile" />
          <i class="fas fa-ellipsis-v" @click="abrirInfo" />
        </div>
      </div>
    </el-header>
    <el-divider />
    <transition name="fade">
      <el-button icon="el-icon-arrow-down" circle v-if="showScrollDown" class="scroll-down" @click="
        descerChat();
      showScrollDown = false;
      " />
    </transition>
    <transition name="el-zoom-in-bottom">
      <div v-if="preview">
        <div v-if="!isArquivoMuitoGrande(this.tamanhoArquivo)" class="container-preview">
          <div class="preview-header">
            <h3>Preview</h3>
            <el-button type="text" @click="preview = false" icon="el-icon-close" />
          </div>
          <div class="preview-file">
            <i class="fas fa-file-alt docGrande" />
            <p class="nome-file-preview">{{ nomeArquivo }}</p>
            <p class="subtitulo-file-preview">{{ tratarTipoArquivo(this.nomeArquivo) }} | {{
              tratarTamanhoArquivo(this.tamanhoArquivo) }}</p>
          </div>
        </div>
        <div v-else class="container-preview">
          <div class="preview-header">
            <h3>Preview</h3>
            <el-button type="text" @click="preview = false" icon="el-icon-close" />
          </div>
          <div class="preview-file">
            <i class="fas fa-exclamation-circle docProibidoGrande" />
            <p class="nome-file-preview">{{ nomeArquivo }}</p>
            <p class="subtitulo-file-preview">{{ tratarTipoArquivo(this.nomeArquivo) }} | {{
              tratarTamanhoArquivo(this.tamanhoArquivo) }}</p>
            <p class="nome-file-preview"><strong>O arquivo é Muito Grande! O tamanho do Arquivo não deve passar de
                50Mb</strong></p>
          </div>
        </div>
        <div v-if="!isArquivoMuitoGrande(this.tamanhoArquivo)" class="preview-bt-send">
          <el-button class="bt-send" circle @click="enviarArquivo" :disabled="loadingSendFile">
            <i v-if="!loadingSendFile" class="fas fa-share" />
            <i v-else class="el-icon-loading ld" />
          </el-button>
        </div>
        <div v-else class="preview-bt-close">
          <el-button class="bt-close" circle @click="preview = false" icon="el-icon-close"></el-button>
        </div>
      </div>
    </transition>

    <div v-if="!preview">
      <ul id="chatroom" :style="`height: ${chatHeight};`" @scroll="handleScroll" v-chat-scroll="{ always: false }"
        ref="scroll">
        <li v-if="showTopLoading" ref="scrollMarginTop" style="height:200px" v-loading="true" />
        <li v-for="(msg) in mensagens" :key="msg.id" :ref="`msg-${msg._id}`">
          <!-- MENSAGEM TIPO TEXT -->
          <div class="box-msg-pai" v-if="msg.tipoMensagem == 'text'" :style="msg.remetenteId == usuarioLogado
            ? 'flex-direction: row-reverse'
            : ''
            ">
            <div :class="msg.remetenteId == usuarioLogado
              ? 'box-msg right'
              : 'box-msg left'
              ">
              <div v-if="!msg.deletado" style="display: flex">
                <div v-touch:swipe.right="reply(msg)">
                  <p :style="makeRandomColor(msg.remetenteId)" v-if="data.tipo != 'PRIVADO' && msg.remetenteId != usuarioLogado
                    ">
                    {{ tratarNomeRemetente(msg.remetenteId) }}
                  </p>
                  <div v-if="msg.mensagemPai" @click="abrirEmMensagemEspecifica(msg, true)">
                    <div style="display: flex; margin: 7px 0">
                      <div :style="makeRandomColor(msg.mensagemPai.remetenteId, true)
                        " class="bar-reply" />
                      <div class="content-reply">
                        <p :style="makeRandomColor(msg.mensagemPai.remetenteId)">
                          {{ makeReplyNome(msg.mensagemPai.remetenteId) }}
                        </p>
                        <span v-if="msg.mensagemPai.deletado == false">{{
                          msg.mensagemPai.preview ||
                          (msg.mensagemPai.metadata &&
                            msg.mensagemPai.metadata.nome)
                        }}</span>
                        <span v-else><i>Mensagem apagada</i></span>
                      </div>
                    </div>
                  </div>
                  <span> {{ msg.conteudo }} </span>
                </div>
                <div style="width: 15px; margin-left: 5px; min-width: 13px;">
                  <el-dropdown class="bt-reply" placement="top" @command="(c) => handleCommand(c, msg)">
                    <span class="el-dropdown-link">
                      <i class="el-icon-arrow-down" />
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="1">
                        <i class="fas fa-reply" style="color: #33cccc" />
                        Responder
                      </el-dropdown-item>
                      <el-dropdown-item command="2" v-if="isRole('ROLE_ORGANIZACAO')">
                        <i class="el-icon-delete-solid" />
                        Apagar
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <div v-else>
                <p :style="makeRandomColor(msg.remetenteId)" v-if="data.tipo != 'PRIVADO' && msg.remetenteId != usuarioLogado
                  ">
                  {{ tratarNomeRemetente(msg.remetenteId) }}
                </p>
                <i style="color: #707070" class="el-icon-warning" />
                <span style="margin: 0 7px">
                  <i>Mensagem apagada</i>
                </span>
              </div>
            </div>

            <span style="color: #a1a1a1; font-size: 10px; margin: 5px">
              {{ tratarDateMsg(msg.dataDeEnvio) }}
              <span class="lida">
                <i :class="'fas ' + getMensagemLida(msg)" />
              </span>
            </span>
          </div>

          <!-- MENSAGEM TIPO FILE -->
          <div class="box-msg-pai" v-else :style="msg.remetenteId == usuarioLogado
            ? 'flex-direction: row-reverse'
            : ''
            ">
            <div :class="msg.remetenteId == usuarioLogado
              ? 'box-msg right'
              : 'box-msg left'
              ">
              <div v-if="!msg.deletado" style="display: flex">
                <div v-touch:swipe.right="reply(msg)">
                  <p :style="makeRandomColor(msg.remetenteId)" v-if="data.tipo != 'PRIVADO' && msg.remetenteId != usuarioLogado
                    ">
                    {{ tratarNomeRemetente(msg.remetenteId) }}
                  </p>
                  <!-- ARQUIVOS -->
                  <div v-if="msg.mimeTypeMensagem != 'audio/mpeg'">
                    <div class="box-file">
                      <i class="el-icon-document" />
                      <div class="file-info">
                        <p class="conteudo-msg">{{ msg.metadata.nome }}</p>
                        <span> {{ tratarTipoArquivo(msg.metadata.nome) }} | {{ tratarTamanhoArquivo(msg.metadata.tamanho)
                        }}</span>
                      </div>
                      <i class="el-icon-download" @click="baixarArquivo(msg, msg.metadata.nome)" />
                    </div>
                  </div>
                  <!-- Áudio Gravado -->
                  <div v-else>
                    <div class="box-file" v-if="!downloadedAudios.some(x => x.id === msg._id)">
                      <i class="fas fa-file-audio" />
                      <div class="file-info">
                        <p class="conteudo-msg">{{ msg.metadata.nome }}</p>
                        <span> {{ tratarTipoArquivo(msg.metadata.nome) }} | {{ tratarTamanhoArquivo(msg.metadata.tamanho)
                        }}</span>
                      </div>
                      <i class="el-icon-download" @click="getAudioUri(msg)" />
                    </div>
                    <div class="box-file" v-else>
                      <i class="fas fa-music" />
                      <div class="file-info" v-if="!msg.metadata.recorded_audio">
                        <p class="conteudo-msg">{{ msg.metadata.nome }}</p>
                        <SimplePlayer :url="downloadedAudios.filter(x => x.id === msg._id)[0].url" :playerid="downloadedAudios.filter(x => x.id === msg._id)[0].id"></SimplePlayer> 
                        <span> {{ tratarTipoArquivo(msg.metadata.nome) }} | {{ tratarTamanhoArquivo(msg.metadata.tamanho)
                        }}</span>
                      </div>  
                      <div class="file-info" v-else>
                        <p class="conteudo-msg">{{ msg.metadata.nome }}</p>
                        <WavePlayer :url="downloadedAudios.filter(x => x.id === msg._id)[0].url" :playerid="downloadedAudios.filter(x => x.id === msg._id)[0].id"></WavePlayer> 
                        <span> {{ tratarTipoArquivo(msg.metadata.nome) }} | {{ tratarTamanhoArquivo(msg.metadata.tamanho)
                        }}</span>
                      </div>
                      <i class="el-icon-download" @click="baixarArquivo(msg, msg.metadata.nome)" />
                    </div>
                  </div>
                </div>
                <div style="width: 5px">
                  <el-dropdown class="bt-reply" placement="top" @command="(c) => handleCommand(c, msg)">
                    <span class="el-dropdown-link">
                      <i class="el-icon-arrow-down" />
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="1">
                        <i class="fas fa-reply" style="color: #33cccc" />
                        Responder
                      </el-dropdown-item>
                      <el-dropdown-item command="2" v-if="isRole('ROLE_ORGANIZACAO')">
                        <i class="el-icon-delete-solid" />
                        Apagar
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <div v-else>
                <p :style="makeRandomColor(msg.remetenteId)" v-if="data.tipo != 'PRIVADO' && msg.remetenteId != usuarioLogado
                  ">
                  {{ tratarNomeRemetente(msg.remetenteId) }}
                </p>
                <i style="color: #707070" class="el-icon-warning" />
                <span style="margin: 0 7px">
                  <i>Mensagem apagada</i>
                </span>
              </div>
            </div>
            <span style="color: #a1a1a1; font-size: 10px; margin: 5px">
              {{ tratarDateMsg(msg.dataDeEnvio) }}
              <span class="lida">
                <i :class="'fas ' + getMensagemLida(msg)" />
              </span>
            </span>
          </div>
        </li>
      </ul>
    </div>
    <div id="chat-text-area" ref="chatArea" @dragover="dragover" @dragleave="dragleave" @drop="drop">
      <div v-if="isDragging">
        <div class="dropzone-container">
          <el-input type="file" name="file" id="fileInput" class="hidden-input" @change="onChange" ref="file"
            accept=".jpg,.jpeg,.png"></el-input>
        </div>
        <span class="label-dropzone">Solte Imagens Aqui</span>
      </div>
      <div v-else>
        <transition name="el-zoom-in-bottom" @after-enter="getChatSize" @after-leave="getChatSize">
          <div v-if="replyMode">
            <div class="box-reply-msg">
              <div v-if="replyMsg" style="display: flex">
                <div :style="makeRandomColor(replyMsg.remetenteId, true)" class="bar-reply" />
                <div class="content-reply bw">
                  <p :style="makeRandomColor(replyMsg.remetenteId)">
                    {{ replyNome }}
                  </p>
                  <span style="color: #707070">
                    {{ replyMsg.conteudo || replyMsg.metadata.nome }}
                  </span>
                </div>
              </div>
              <el-button type="text" icon="el-icon-close" @click="closeReply" />
            </div>
          </div>
        </transition>
        <div class="chatbox" v-loading="loadingChat">
          <div style="width: 100%;">
            <div v-if="isRecording">
              <span>Atenção! Será gravado no máximo 50 minutos de áudio após isso o áudio será enviado
                automaticamente!</span><br />
              <span class="recodingDuration">Gravando áudio... - {{ recordedTime }}</span>
            </div>
            <twemoji-textarea id="msg" :maxlength="2000" ref="twemoji" :emojiData="emojiDataAll"
              :emojiGroups="emojiGroups" placeholder="Digite aqui" :searchEmojisFeat="true"
              searchEmojiPlaceholder="Pesquisar Emoji" isLoadingLabel="Pesquisando..."
              searchEmojiNotFound="Emoji não encontrado" @enterKey="onEnterKey" @contentChanged="fillConteudo"
              :enableSendBtn="true" :textareaDisabled="isSendingRequest || !isAlive" twemojiExtension=".svg"
              twemojiFolder="svg" twemojiPath="https://cdn.jsdelivr.net/npm/twemoji@12.0.2/2/"
              v-if="!preview && !isRecording" />
          </div>
          <i :class="iconButtonType" @click="toggleRecorder" />
        </div>
      </div>
    </div>
  </el-container>
</template>

<script src="path/to/vue.js"></script>
<script src="path/to/vue-touch-events.js"></script>

<script>
import "../css/theme.css";
import eventBus from "../eventBus";
import KonnectService from "../services/KonnectEnterpriseService.js";
import InfoChat from "./InfoChat.vue";
import moment from "moment";
import tinyColor from "tinycolor2";
import AuthUtils from "../utils/AuthUtils.js";

import { Notification } from "element-ui";
import { TwemojiTextarea } from "@kevinfaguiar/vue-twemoji-picker";
import EmojiAllData from "../assets/emojis/emojis-all-data.json";
import EmojiGroups from "@kevinfaguiar/vue-twemoji-picker/emoji-data/emoji-groups.json";

import Vue from "vue";
import VueChatScroll from "vue-chat-scroll";
import Vue2TouchEvents from "vue2-touch-events";
import ClipboardJS from 'clipboard';
import * as clipboardPolyfill from "clipboard-polyfill";
import KonnectEnterpriseService from "../services/KonnectEnterpriseService.js";
import Mensagem from "../models/Mensagem";

import axios from "axios";
import download from "js-file-download";

import Recorder from "../utils/audio/audio-recorder/recorder.js"
import { convertTimeMMSS } from '@/utils/audio/audio-recorder/convertTime.js'

import SimplePlayer from "@/utils/audio/audio-player/SimplePlayer"
import WavePlayer from "@/utils/audio/audio-player/WavePlayer"



Vue.use(VueChatScroll);
Vue.use(Vue2TouchEvents);

export default {
  name: "Chat",
  components: {
    "twemoji-textarea": TwemojiTextarea,
    VueChatScroll,
    InfoChat,
    Vue2TouchEvents,
    SimplePlayer,
    WavePlayer
  },
  props: {},

  data() {
    return {
      isRole: AuthUtils.isRole,

      MAX_FILE_SIZE: 52428800,

      chatHeight: "80vh",

      usuarioLogado: null,
      mensagens: [],
      corUsuario: [],
      isSendingRequest: false,
      isScrolling: false,
      loadingSendFile: false,
      infoAberta: false,
      preview: false,
      chatSincronizado: true,
      showTopLoading: false,
      showScrollDown: false,
      isAlive: true,
      isDragging: false,

      ultimaVisualizacaoTodos: null,

      msgBuscada: null,
      scrollToMessage: false,

      replyMode: false,
      replyMsg: null,
      replyNome: null,

      filebase64: null,
      nomeArquivo: null,
      tamanhoArquivo: null,
      reqBody: {
        conteudo: null,
        tipoMensagem: null,
        metadata: null,
        mensagemPai: null,
      },

      // Variáveis de controle para a requisição
      pkIndexAnterior: null,
      pkIndexPosterior: null,

      token: window.localStorage.getItem("TokenSAS"),
      kmsx:process.env.VUE_APP_KMSX_SERVICE_AZURE_STORAGE,

      recordFile: null,
      recorder: this._initRecorder(),
      time: 50,

      loadingChat: false,
      downloadedAudios: [],
    };
  },
  computed: {
    data() {
      return this.$store.getters.chatAtual;
    },
    emojiDataAll() {
      return EmojiAllData;
    },
    emojiGroups() {
      return EmojiGroups;
    },
    nomeCanal() {
      if (this.data?.tipo == "PRIVADO") {
        let membro = this.data.membros.find((m) => m.id != this.usuarioLogado);
        return membro.nome;
      } else return this.data?.nome;
    },

    //Audio_Recorder
    iconButtonType () {
      return !this.isRecording ? 'fas fa-microphone' : 'fas fa-stop-circle'
    },
    isRecording () {
      return this.recorder.isRecording
    },
    recordedTime () {
      if (this.time && this.recorder.duration >= this.time * 60) {
        this.endingRecording()
      }
      return convertTimeMMSS(this.recorder.duration)
    },
  },

  async mounted() {
    document.querySelector("#msg").onkeydown = async (e) => {
      if (e.ctrlKey) {
        if (e.keyCode === 86) {

          await this.getPermissionFromBrowser("clipboard-read");
          
          try{
            await this.getImageFromBrowserClipboard();
          }
          catch(error){
            console.log(error);
          }
        }
      }
    };
    // Recupera e limpa mensagens do store
    this.mensagens = JSON.parse(
      JSON.stringify(await this.$store.getters.chatMsgs)
    );
    this.$store.dispatch("CLEAN_CHAT_MSGS");

    // Verifica se o usuário veio de um canal
    if (this.data == null) {
      this.$router.push({ name: "Mensagens" }).catch(() => {});
    }

    const clipboard = new ClipboardJS('input');

    // Remove os listeners
    eventBus.$off("novaMensagem");
    eventBus.$off("deletarMensagem");
    eventBus.$off("tratarConexaoWebsocket");

    // Reconfigura
    eventBus.$on("novaMensagem", (msg) => this.receberUltimaMensagem(msg));
    eventBus.$on("deletarMensagem", (msg) => this.deletarMensagem(msg));

    setTimeout(() => this.$refs?.twemoji?.focus());
    this.usuarioLogado = JSON.parse(window.localStorage.getItem("usuario")).id;

    // Se tiver uma mensagem para ser visualizada...
    if (!this.$store.getters.deveNavegarAteMensagem) {
      this.getMensagensMaisNovasDoCanal();
    }

    this.gerarCorUsuarios();

    this.obterUltimaVisualizacaoDosMembros();

    eventBus.$on("tratarConexaoWebsocket", (status) => {
      if (status == "Failed" || status == "Retrying") this.isAlive = false;
      else {
        this.getMensagensMaisNovasDoCanal();
        this.isAlive = true;
      }
    });
    window.addEventListener("resize", this.getChatSize);
    this.getChatSize();
  },

  updated() {
    if(document.querySelector("#msg")){
      document.querySelector("#msg").onkeydown = async (e) => {
        if (e.ctrlKey) {
          if (e.keyCode === 86) {
            await this.getPermissionFromBrowser("clipboard-read");
            try{
              await this.getImageFromBrowserClipboard();
            }catch(error){
              console.log(error);
            }
          }
        }
      };
    }
    // Se tiver uma mensagem para ser visualizada...
    if (this.$store.getters.deveNavegarAteMensagem) {
      const msg = JSON.parse(
        JSON.stringify(this.$store.getters.deveNavegarAteMensagem)
      );
      this.abrirEmMensagemEspecifica(msg, false);
      this.$store.dispatch("CLEAN_DEVE_NAVEGAR_ATE_MENSAGEM");
    }

    if (this.scrollToMessage) {
      this.$nextTick()
        .then(() => {
          if (this.chatSincronizado && this.msgBuscada && this.msgBuscada._id) {
            let targetLi = this.$refs[`msg-${this.msgBuscada._id}`][0],
              scrollTo = targetLi.offsetTop - 70;
            this.$refs.scroll.scroll({ top: scrollTo, behavior: "smooth" });
          } else this.$refs.scroll.scroll({ top: 5, behavior: "smooth" });
          this.$nextTick(() =>
            this.darEnfaseEmMensagem(
              this.$refs[`msg-${this.msgBuscada._id}`][0]
            )
          );
          this.scrollToMessage = false;
        })
        .finally(() => {
          this.msgBuscada = null;
          this.scrollToMessage = false;
        });
    }
  },

  watch: {
    mensagens() {
      if (
        this.chatSincronizado &&
        (!this.showScrollDown || this.isScrollOnBottom())
      ) {
        this.marcarComoVisto();
      }
    },
    chatSincronizado() {
      if (
        this.chatSincronizado &&
        (!this.showScrollDown || this.isScrollOnBottom())
      ) {
        this.marcarComoVisto();
      }
    },
  },

  methods: {

    isArquivoMuitoGrande(size){
      return size>this.MAX_FILE_SIZE;
    },
    onChange() {
			this.getImageFromBlob(this.$refs.file.files[0]);
		},
		dragover(e) {
			e.preventDefault();
			this.isDragging = true;
		},
		dragleave() {
			this.isDragging = false;
		},
		drop(e) {
			e.preventDefault();
			this.$refs.file.files = e.dataTransfer.files;
			this.onChange();
			this.isDragging = false;
		},
    async getPermissionFromBrowser(permissionName){
        const permission = await navigator.permissions.query({
              name: permissionName,
          });
          if (permission.state === "denied") {
              throw new Error(`Permissão: '${permissionName}' não habilitada, algumas funções do Sistema podem não funcionar da maneira correta.`);
          }
    },
    async getImageFromBrowserClipboard(){
      const clipboardContents = await navigator.clipboard.read();
      for (const item of clipboardContents) {
        for (const type of item.types) {
          switch (type) {
            case "image/png":
              this.getImageFromBlob(await item.getType("image/png"));
              break;
            case "image/jpg":
              this.getImageFromBlob(await item.getType("image/jpg"));
              break;
            case "image/jpeg":
              this.getImageFromBlob(await item.getType("image/jpeg"));
              break;
            default:
              break;
          }
        }
      }
    },
    getImageFromBlob(blob){
      var reader = new FileReader();
      this.nomeArquivo = blob.name||`image.png`;
      this.tamanhoArquivo = blob.size;
      this.preview = true;
      this.showScrollDown = false;
      reader.readAsBinaryString(blob);
      reader.onload = () => (this.filebase64 = btoa(reader.result));
    },
    isScrollOnTop() {
      return (
        this.$refs.scroll.scrollTop < this.$refs.scrollMarginTop.scrollHeight
      );
    },
    isScrollOnBottom() {
      return (
        this?.$refs?.scroll?.scrollHeight - this?.$refs?.scroll?.scrollTop ===
        this?.$refs?.scroll?.clientHeight
      );
    },
    abrirInfo() {
      this.infoAberta = true;
      this.$store.dispatch("SET_CHAT_MSGS", this.mensagens);
      this.$router.push({ name: "Info" });
    },
    getChatSize() {
      let userBarHeight = document.getElementById("userBar")?.clientHeight;
      if (!userBarHeight) userBarHeight = 0;

      this.chatHeight = `${window?.innerHeight -
        ((this?.$refs?.chatArea?.clientHeight || 0) +
          (this?.$refs?.chatHeader?.$el?.clientHeight || 0) +
          18) -
        userBarHeight}px`;
    },
    obterUltimaVisualizacaoDosMembros() {
      var data = this.data?.membros?.reduce((dataMinima, membro) => {
        return new Date(membro.ultimaVisualizacao).getTime() <
          new Date(dataMinima).getTime()
          ? dataMinima
          : membro.ultimaVisualizacao;
      });
      this.ultimaVisualizacaoTodos = new Date(data);
    },
    getMensagemLida(mensagem) {
      if (
        new Date(mensagem.dataDeEnvio).getTime() <=
        this.ultimaVisualizacaoTodos.getTime()
      ) {
        return "fa-check-double";
      } else if (mensagem.dataDeEnvio != null) {
        return "fa-check";
      } else {
        return "fa-clock";
      }
    },
    marcarComoVisto() {
      KonnectEnterpriseService.marcarComoVisto(
        this.data._id
      ).catch((error) => {});
    },
    reply(msg) {
      return () => {
        this.replyMsg = msg;
        this.replyMode = true;
        this.replyNome = this.makeReplyNome(msg.remetenteId);
        setTimeout(() => this.$refs.twemoji.focus());
      };
    },
    closeReply() {
      this.replyMode = false;
      this.replyMsg = null;
    },
    voltarInicio(c) {
      if (c) this.$emit("excluirCanal", c);
      else this.infoAberta = false;
    },
    voltar() {
      this.infoAberta = false;
      this.$store.dispatch("SET_CHAT", null);
      this.$router.push({ name: "Mensagens" });
    },
    tratarDateMsg(hora) {
      return moment(hora).format("DD-MM-YYYY HH:mm");
    },
    tratarTipoArquivo(nomeArquivo){
      // retorna o Tipo escrito no Nome da mensagem, caso nao tenha retorna como 'Arquivo'
      if (nomeArquivo.lastIndexOf(".")===-1) return "Arquivo";
      return nomeArquivo.substring(nomeArquivo.lastIndexOf(".")+1,nomeArquivo.length).toUpperCase() ;
      // return nomeArquivo.substring(nomeArquivo.indexOf("/")+1,nomeArquivo.length).toUpperCase();
    },
    tratarTamanhoArquivo(tamanho, decimais = 2){
      // Retorna N/A caso 'tamanho' nao seja um numero ou tenha valor nulo ou zero 
      if (!+tamanho) return "N/A"

      const k = 1024
      const dm = decimais < 0 ? 0 : decimais
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(tamanho) / Math.log(k))

      //Retorna o tamanho convertido na escala correta e com as casas decimais solicitada
      return `${parseFloat((tamanho / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    makeReplyNome(id) {
      if (this.usuarioLogado == id) return "Você";
      else return this.tratarNomeRemetente(id);
    },
    tratarNomeRemetente(id) {
      let u = this.data.membros.filter((u) => u.id == id);
      if (u.length > 0) return u[0].nome;
      else return "Usuário Removido"; 
    },
    makeRandomColor(id, bool) {
          let u = this.corUsuario.filter((c) => c.id == id);
          if (u.length > 0 && !bool) return `color: ${u[0].cor}; font-weight: 600`;
          else if (u.length > 0 && bool) return `background-color: ${u[0].cor}`;
          else this.gerarCorUsuarios(id);
    },
    gerarCorUsuarios(idUsuario) {
      let style = { cor: tinyColor.random().toHexString(), id: idUsuario };
      if (this.data.membros.filter(m => m.id == idUsuario).length == 0) {
        style = { cor: "#cccccc", id: idUsuario };
      } 
        if (tinyColor(style.cor).isLight())
          style.cor = tinyColor(style.cor)
            .darken(35)
            .toString();
        this.corUsuario.push(style);
    },
    receberUltimaMensagem(msg) {
      if (!this.mensagens) this.mensagens = [];
      if (msg.canalId == this.data._id) {
        this.mensagens.push(msg);
      }
    },
    deletarMensagem(msg) {
      // Se não for uma mensagem do canal, só retorna
      if (msg.canalId !== this.data._id) return;

      // Atualiza
      Vue.set(
        this.mensagens,
        this.mensagens.findIndex((x) => x._id === msg._id),
        msg
      );

      // Atualiza mensagens filhas, se necessário
      const children = this.mensagens?.filter(
        (x) => x?.mensagemPai?._id === msg._id
      );
      if (children && children.length !== 0)
        children.forEach((x) => {
          x.mensagemPai = msg;
        });
    },
    tratarMensagens(msgs) {
      if (msgs) {
        return msgs.sort((a, b) => {
          return a.dataDeEnvio.localeCompare(b.dataDeEnvio);
        });
      }
    },
    onEnterKey(e) {
      if (this.reqBody.conteudo) {
        this.reqBody.tipoMensagem = "text";
        if (this.replyMsg)
          this.reqBody.mensagemPai = {
            id: this.replyMsg._id,
            pk: this.replyMsg.pk,
          };

        var mensagem = new Mensagem(
          this.reqBody.conteudo,
          this.replyMsg,
          this.reqBody.tipoMensagem,
          this.usuarioLogado,
          this.data._id
        );
        this.receberUltimaMensagem(mensagem);

        KonnectService.postMensagem(this.reqBody, this.data._id)
          .then((r) => {
            this.mensagens.splice(
              this.mensagens.findIndex((m) => m == mensagem),
              1
            );
            this.$refs.twemoji.cleanText();
            this.replyMode = false;
            eventBus.$emit("reordenarCanais", this.data._id, new Date());
          })
          .catch((e) => {
            if (e.response.status == 503) {
              Notification.closeAll();
              switch (e.response.headers["x-websocket-status"]) {
                case "OK":
                  console.log("mensagem enviada");
                  break;
                case "RETRYING":
                  Notification.warning({
                    title: "Perda de conexão",
                    message:
                      "Tentando restabelecer conexão, tente novamente em instantes.",
                    duration: 0,
                  });
                  break;
                case "FAILED":
                  this.isAlive = false;
                  Notification.error({
                    showClose: false,
                    title: "Perda de conexão",
                    message:
                      "Atualize a página, caso o erro persistir entre em contato com o suporte da Klaus.",
                    duration: 0,
                  });
                  break;

                default:
                  this.isAlive = false;
                  Notification.error({
                    showClose: false,
                    title: "Perda de conexão",
                    message:
                      "Atualize a página, caso o erro persistir entre em contato com o suporte da Klaus.",
                    duration: 0,
                  });
                  break;
              }
            }else{
              this.$notify({
                title: "Erro ao enviar mensagem",
                message: e.response.headers["x-reason"],
                type: "warning",
              })
            }
          });
        this.reqBody.conteudo = null;
        this.reqBody.mensagemPai = null;
        this.replyMsg = null;
        this.isSendingRequest = false;
        this.$refs.twemoji.focus();
        this.descerChat();
      }
    },
    descerChat() {
      this.$refs.scroll.scroll({
        top: this.$refs.scroll.scrollHeight,
        behavior: "smooth",
      });
      this.showScrollDown = false;

      if (!this.chatSincronizado) {
        this.mensagens = [];
        this.getMensagensMaisNovasDoCanal();
      }
    },
    fillConteudo(val) {
        this.reqBody.conteudo = val;
    },
    onPickFile() {
      this.$emit("onPickFile");
      this.$refs.fileInput.click();
    },
    handleCommand(c, msg) {
      if (c == 1) {
        this.replyMsg = msg;
        this.replyMode = true;
        this.replyNome = this.makeReplyNome(msg.remetenteId);
        setTimeout(() => this.$refs.twemoji.focus());
      } else {
        this.isSendingRequest = true;
        this.$confirm(
          `Tem certeza que deseja apagar esta mensagem?`,
          "Atenção!",
          {
            confirmButtonText: "Sim",
            cancelButtonText: "Cancelar",
            type: "warning",
          }
        )
          .then(() => {
            KonnectService.mensagensDeleteByPkAndId(
              this.data._id,
              msg.pk,
              msg._id
            )
              .then((r) => {
                if (r.status == 200) {
                  let m = this.mensagens.filter((m) => m._id == msg._id);
                  m[0].deletado = true;
                  this.$message({
                    type: "success",
                    message: `Mensagem apagada!`,
                  });
                }
              })
              .catch((e) => console.log("deu errado", e));
          })
          .catch(() => {})
          .finally(() => (this.isSendingRequest = false));
      }
    },
    onFilePicked(e) {
      var reader = new FileReader();
      let file = e.target.files[0];
      this.nomeArquivo = file.name;
      this.tamanhoArquivo = file.size;
      this.preview = true;
      this.showScrollDown = false;
      reader.readAsBinaryString(file);
      reader.onload = () => (this.filebase64 = btoa(reader.result));
    },
    enviarArquivo() {
      this.loadingSendFile = true;
      this.reqBody.tipoMensagem = "file";
      this.reqBody.metadata = { nome: `${this.nomeArquivo}`, tamanho: `${this.tamanhoArquivo}` };
      this.reqBody.conteudo = this.filebase64.replace(
        "data:text/html;base64,",
        ""
      );
      KonnectService.postMensagem(this.reqBody, this.data._id)
        .then((r) => (this.preview = false))
        .catch((e) =>
          this.$notify({
            title: "Erro ao enviar arquivo",
            message: e.response.headers["x-reason"],
            type: "warning",
          })
        )
        .finally(() => {
          this.reqBody.metadata = null;
          this.loadingSendFile = false;
        });
    },
    baixarArquivo(msg, nome) {
      this.isSendingRequest = true;

      KonnectService.mensagensGetFileUriByPkAndId(
        this.data._id,
        msg.pk,
        msg._id
      ).then((r) => {
        axios
          .get(r.url, { responseType: "blob" })
          .then((r) => download(r.data, nome))
          .catch((e) =>
            this.$notify({
              title: "Erro ao baixar arquivo.",
              message: e.response.headers["x-reason"],
              type: "warning",
            })
          )
          .finally(() => (this.isSendingRequest = false));
      });
    },
    darEnfaseEmMensagem(elemento) {
      let msgParaEstilizar = elemento?.querySelector(".box-msg");

      // Aguarda o scroller subir, adiciona a classe da animação na mensagem
      setTimeout(() => {
        msgParaEstilizar?.classList?.add("msg-com-animacao");
      }, 350);

      // Dá um tempo para a animaçaõ finalizar com segurança e depois remove a classe
      setTimeout(() => {
        msgParaEstilizar?.classList?.remove("msg-com-animacao");
      }, 1400);
    },
    async handleScroll() {
      // Se nada estiver carregando
      if (!this.isSendingRequest && !this.isScrolling) {
        // Se o scroll estiver no topo
        if (this.isScrollOnTop()) await this.carregarLoteAnterior();

        // Se o scroll estiver na base
        if (this.isScrollOnBottom() && !this.chatSincronizado) {
          await this.carregarLotePosterior(
            this.mensagens[this.mensagens.length - 1]
          );
        }
      }

      // Se o scroll estiver posicionado NÃO estive em baixo ou se o chat não estiver
      // sincronizado mostra o ícone para descer a conversa
      this.showScrollDown = !this.isScrollOnBottom() || !this.chatSincronizado;
    },
    /**
     * @param {(1|2|3)} modo  </br>1: aparece a mensagem no topo da pagina com um padding; </br> 2: aparece a mensagem no fim da pagina quase sumindo; </br> 3: Mensagem aparece no meio;
     */
    scrollAteMensagem(msg, modo) {
      // Indica que está "scrollando"
      this.isScrolling = true;

      // Força o update
      this.$forceUpdate();

      // Se a mensagem foi passada e ela não estiver na lista local, busca ela na db
      if (!this.mensagens.find((m) => m._id === msg._id))
        return this.abrirEmMensagemEspecifica(msg);

      // Faz o scroll
      this.$nextTick(() => {
        // Recupera a mensagem
        const refMsg = this.$refs[`msg-${msg?._id}`];
        const targetLi = refMsg ? refMsg[0] : null;

        // Escolhe como será feito o scroll
        let args = [];
        switch (modo) {
          case 1:
            args = [{ top: targetLi?.offsetTop - 100 }];
            break;
          case 2:
            // Calculo para mensagem aparecer como a ultima mensagem sendo exibida
            let c =
              targetLi.offsetTop -
              this.$refs.scroll.clientHeight -
              targetLi.clientHeight -
              100;

            // Se por acaso o chat estiver sincronizado e estiver no final: só vai até o fim
            if (this.chatSincronizado && c > this.$refs.scroll.clientHeight) {
              c = this.$refs.scroll.clientHeight;
            }

            args = [0, c];
            break;
          case 3:
            args = [
              { top: targetLi.offsetTop - this.$refs.scroll.clientHeight / 2 },
            ];
            break;
          default:
            throw new Error(
              "Erro no 'scrollAteMensagem'. Modo inválido. Favor ler a documentação do método."
            );
        }

        // Faz o scroll
        this.$refs?.scroll?.scroll(...args);

        // Força o update
        this.$forceUpdate();

        // Destaca a msg
        this.darEnfaseEmMensagem(targetLi);

        // Indica que não está mais "scrollando"
        this.isScrolling = false;
      });
    },
    async preencherAtéAparecerScroll(goToMsg) {
      if (
        this?.$refs?.scroll?.scrollHeight <= this?.$refs?.scroll?.clientHeight
      )
        return await this?.carregarLoteAnterior(null, goToMsg);
      else this.showTopLoading = true;

      // Faz o scroll
      this.scrollAteMensagem(goToMsg, 1);
    },

    // ---------------------------------------------> Métodos de busca de mensagem
    //  '-> Mensagens mais atuais do canal
    async getMensagensMaisNovasDoCanal() {
      // Se não tiver as informações minimas para executar a request, nem tenta
      if (!this.data?._id || !this.data?.currentMessagesPkIndex) return;

      // Atualiza variaveis de controle
      this.pkIndexAnterior = this.data?.currentMessagesPkIndex;
      this.pkIndexPosterior = this.data?.currentMessagesPkIndex;

      // Tenta...
      try {
        // Mostra o isSendingRequest
        this.isSendingRequest = true;

        // Faz a request
        const r = await KonnectService.mensagensPkGetUltimas(
          this.data?._id,
          this.data?.currentMessagesPkIndex
        );

        // Caso não tenha encontrado mensagens, volta para a pk anterior
        if (r.status === 204) this.irParaPkAnterior();

        // Caso tenha encontrado mensagens
        if (r.status === 200 && r.data) {
          // Atualiza lista
          this.mensagens = r.data;
          this.tratarMensagens(this.mensagens);

          await this.preencherAtéAparecerScroll(r.data[r.data.length - 1]);
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.isSendingRequest = false;
      }
    },

    //  '-> Abrir em mensagem específica
    async abrirEmMensagemEspecifica(msg, navegarParaMensagemPai = false) {
      // Recupera a mensagem que deve ser navegada.
      // Se foi definido que é a mensagem pai ou se é a própria mensagem
      const mensagemASerBuscada = navegarParaMensagemPai
        ? msg.mensagemPai
        : msg;

      // Busca na lista atual de mensagens
      const mensagem = this.mensagens.filter(
        (m) => m._id == mensagemASerBuscada._id
      )[0];

      // Se a mensagem FOI encontrada
      if (mensagem) return this.scrollAteMensagem(mensagemASerBuscada, 3);

      // Caso contrário...
      // Busca a mensagem do backend
      const msgResponse = (
        await KonnectService.mensagensGetByPkAndId(
          this.data._id,
          mensagemASerBuscada.pk,
          mensagemASerBuscada._id
        )
      ).data;

      // Atualiza valores de controle
      this.mensagens = [msgResponse];
      this.pkIndexAnterior = msgResponse.pkIndex;
      this.pkIndexPosterior = msgResponse.pkIndex;
      this.chatSincronizado = false;

      // Atualiza
      this.$forceUpdate();

      // Busca o lote posterior
      await this.carregarLotePosterior();

      // Caso não exista mensagens posteriores carrega mensagens anteriores
      await this.carregarLoteAnterior(null, msgResponse);

      // Faz a mensagem aparecer no meio
      this.scrollAteMensagem(mensagemASerBuscada, 3);
    },

    //  '-> Navegação entre pks (Partition Keys)
    async irParaPkAnterior(_, goToMsg) {
      // Se a pkIndexAnterior já for 1, então acabou as mensagens ^_^
      if (this.pkIndexAnterior === 1) {
        // Esconde o isSendingRequest depois de um tempo
        const cb = () =>
          this?.$refs?.scroll?.scroll({
            top: this?.$refs?.scrollMarginTop?.scrollHeight,
            behavior: "smooth",
          });
        setTimeout(cb, 500);

        // Finaliza
        return;
      }

      // Tenta...
      try {
        // Mostra o isSendingRequest
        this.isSendingRequest = true;

        // Gera o novo index
        const novoIndexAnterior = this.pkIndexAnterior - 1;

        // Faz a request
        const r = await KonnectService.mensagensPkGetUltimas(
          this.data?._id,
          novoIndexAnterior
        );

        // Caso não tenha encontrado mensagens, volta para a pk anterior
        if (r.status === 204) this.irParaPkAnterior(_, goToMsg);

        // Caso tenha encontrado mensagens, atualiza informações
        if (r.status === 200 && r.data) {
          this.pkIndexAnterior = novoIndexAnterior;
          this.mensagens.push(...r.data);
          this.tratarMensagens(this.mensagens);

          await this.preencherAtéAparecerScroll(goToMsg || r.data[0]);
        }
      } catch (e) {
        console.log(e);
      } finally {
        this.isSendingRequest = false;
      }
    },
    async irParaPkPosterior() {
      // Se o chat estiver sincronizado, então acabou as mensagens ^_^
      if (this.chatSincronizado) return;

      // Tenta...
      try {
        // Mostra o isSendingRequest
        this.isSendingRequest = true;

        // Gera o novo index
        const novoIndexPosterior = this.pkIndexPosterior + 1;

        // Faz a request
        const r = await KonnectService.mensagensPkGetPrimeiras(
          this.data?._id,
          novoIndexPosterior
        );

        // Se o retorno foi 204 (no content, ou seja, vazio)
        if (r.status === 204) {
          // Caso contrário, chegou nas mensagens atuais e atualiza as variaveis
          this.chatSincronizado = true;

          // Mostra o botão para ir para as mensagens mais recentes
          this.showScrollDown = false;
        }

        // Caso tenha encontrado mensagens
        if (r.status === 200 && r.data) {
          this.mensagens.push(...r.data);
          this.tratarMensagens(this.mensagens);
        }

        // Atualiza a nova pk
        this.pkIndexPosterior = novoIndexPosterior;
      } catch (e) {
        console.log(e);
      } finally {
        this.isSendingRequest = false;
      }
    },

    //  '-> Navegação entre lotes de mensagens (subdivisões) das pks (Partition Keys)
    async carregarLoteAnterior(_, goToMsg) {
      // Tenta...
      try {
        // Mostra o isSendingRequest
        this.isSendingRequest = true;

        // Recupera a primeira mensagem
        let msg = this.mensagens[0];

        // Faz a requisição
        const r = await KonnectService.mensagensPkGetAntesDaMsg(
          this.data._id,
          this.pkIndexAnterior,
          msg._id
        );

        // Caso não tenha encontrado mensagens, volta para a pk anterior
        if (r.status === 204) this.irParaPkAnterior(_, goToMsg);

        // Caso tenha encontrado mensagens
        if (r.status === 200 && r.data) {
          // Atualiza informações
          this.mensagens.push(...r.data);
          this.tratarMensagens(this.mensagens);

          await this.preencherAtéAparecerScroll(goToMsg || r.data[0]);
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isSendingRequest = false;
      }
    },
    async carregarLotePosterior() {
      try {
        // Mostra o isSendingRequest
        this.isSendingRequest = true;

        // Recupera a ULTIMA mensagem
        const msg = this.mensagens[this.mensagens.length - 1];

        // Faz o scroll
        this.scrollAteMensagem(msg, 2);

        // Faz a requisição
        const r = await KonnectService.mensagensPkGetDepoisDaMsg(
          this.data._id,
          this.pkIndexPosterior,
          msg._id
        );

        // Se o retorno foi 204 (no content, ou seja, vazio)
        if (r.status === 204) {
          // E se ainda não estiver na PK atual do canal, vai uma para frente
          if (this.pkIndexPosterior !== this.currentMessagesPkIndex)
            this.irParaPkPosterior();
        }

        // Se a respota foi 200, adiciona e trata as mensagens
        if (r.status === 200 && r.data) {
          // Adiciona as mensagens na lista
          this.mensagens.push(...r.data);

          // Ordena as mensagens
          this.tratarMensagens(this.mensagens);

          // Mostra o botão para ir para as mensagens mais recentes
          this.showScrollDown = true;

          // Indica que o chat não está sincronizado
          this.chatSincronizado = false;
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.isSendingRequest = false;
      }
    },

    toggleRecorder () {
        if (!this.isRecording) {
          this.recorder.start()
        } else {
          this.endingRecording()
        }
      },

    _initRecorder () {
      return new Recorder({
        beforeRecording : this.beforeRecording,
        afterRecording  : this.afterRecording,
        micFailed       : this.micFailed,
        bitRate         : 128,
        sampleRate      : 44100
      })
    },

    beforeRecording(){
      console.log("Iniciando Gravação...");
    },

    afterRecording(){
      console.log("Parando Gravação...")
    },

    micFailed(){
      this.$message({
        message:"Ative a permissão do microfone para usar essa função!",
        type:"error",
      });
      this.recorder.isRecording = false;
    },

    async endingRecording(){
      this.loadingChat = true;
      this.recorder.stop();
      this.recordFile = this.recorder.recordFile();
      const type = this.recordFile.blob.type.split('/')[1];
      this.nomeArquivo = `${JSON.parse(window.localStorage.getItem("usuario")).nome} - ${moment(new Date()).format("DD/MM/YYYY-HH:mm:ss")} - AUDIO.${type}`;

      this.tamanhoArquivo = this.recordFile.blob.size;

      var reader = new FileReader();
      
     this.filebase64 = await new Promise((resolve, _) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(this.recordFile.blob);
      });
    

      this.reqBody.tipoMensagem = "file";
      this.reqBody.metadata = { nome: `${this.nomeArquivo}`, tamanho: `${this.tamanhoArquivo}`, recorded_audio: true};
      this.reqBody.conteudo = this.filebase64.replace(
        "data:audio/mp3;base64,",
        ""
      );;

      KonnectService.postMensagem(this.reqBody, this.data._id)
        .catch((e) =>
          this.$notify({
            title: "Erro ao enviar áudio",
            message: e.response.headers["x-reason"],
            type: "warning",
          })
        )
        .finally(() => {
          this.reqBody.metadata = null;
          this.loadingChat = false;
        });      
    },
    getAudioUri(msg){
    this.isSendingRequest = true;

    KonnectService.mensagensGetFileUriByPkAndId(
        this.data._id,
        msg.pk,
        msg._id
      ).then((r) => {
        console.log(r);
          this.downloadedAudios.push({url: r.url, id: msg._id})
      }).catch((e) =>
            this.$notify({
              title: "Erro ao baixar Áudio.",
              message: e.response.headers["x-reason"],
              type: "warning",
            })
          )
          .finally(() => (this.isSendingRequest = false));
    },

  },


  beforeDestroy() {
    if(this.isRecording)
      this.recorder.stop();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style scoped>
.dropzone-container {
  position: relative;
  z-index: 3 !important;
  width: 100%;
  height: 100px;
  opacity: 100;
  padding: 2px;
  border: 4px dashed #0060b3;
  background-color: transparent;
  border-radius: 20px;
}

.label-dropzone {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 16px;
  z-index: 2;
}

.hidden-input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
  visibility: hidden;
  width: 1px;
  height: 1px;
}

#chat-text-area {
  position: relative;
  top: 10px;
  width: 100%;
  z-index: 1;
  border-top: 1px solid #dddddd;
}

.el-main {
  padding: 0px !important;
}

.chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
}

.chat-header span,
.ar {
  color: #03283f;
  font-weight: 600 !important;
}

.circle-icon {
  margin-right: 10px;
}

>>>.p,
>>>.g,
>>>.e {
  color: #fff;
  font-size: 18px;
}

.chat-title {
  display: flex;
  align-items: center;
  max-width: 60%;
  height: 100%;
}

.chat-title span {
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  padding: 0px 10px;
}

.fa-ellipsis-v,
.fa-paperclip {
  color: #03283f;
  font-size: 15px;
}

#chatroom {
  padding: 0 0 2px 0;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: 0;
  list-style-type: none;
  background-image: url("../assets/images/wallpapers/backgroundfinalessecorretojuro@300x.png");
  background-color: var(--segunda-cor-claro);

}

.box-msg-pai {
  display: flex;
  align-items: center;
  margin: 10px;
}

.box-msg {
  max-width: 75%;
  padding: 5px 13px;
  border-radius: 7px;
  overflow-wrap: anywhere;
  display: flex;
  align-items: center;
  position: relative;
}

.right {
  background-color: #e4ffff;
  text-align: end;
  border-bottom: 2px solid #cee9e9;
  border-right: 1px solid #cee9e9;
}

.left {
  background-color: #fff;
  text-align: initial;
  border-bottom: 2px solid #eae8e8;
  border-right: 1px solid #eae8e8;
}

.box-msg span,
.box-msg p {
  color: #707070;
  font-size: 1.05em;
}

.scroll-down,
.scroll-down:focus,
.scroll-down:hover {
  box-shadow: 3px 3px 10px #dddddd;
  position: absolute;
  color: #ffff;
  font-size: 15px;
  background: #0060b3;
  right: 35px;
  bottom: 105px;
  z-index: 99;
}

/* PREVIEW DE ARQUIVOS */
.container-preview {
  height: 70vh;
  display: flex;
  flex-direction: column;
}

.preview-header {
  display: flex;
  margin: 3px 15px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #dddddd;
  padding: 0 20px;
}

.preview-header h3 {
  font-size: 17px;
  color: #03283f;
  font-weight: 600;
}

.el-button--text {
  color: #909090 !important;
}

.preview-file {
  display: flex;
  width: 100%;
  height: 55vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.nome-file-preview {
  color: rgb(101, 101, 101);
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 80vw;
}

.subtitulo-file-preview {
  color: rgb(196, 196, 196);
  font-style: italic;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 80vw;
}

.docGrande {
  font-size: 40px;
  color: #409eff;
  margin-bottom: 30px;
}

.docProibidoGrande {
  font-size: 40px;
  color: #FF4000;
  margin-bottom: 30px;
}

.preview-bt-send {
  display: flex;
  justify-content: flex-end;
  margin-right: 25px;
}

.preview-bt-close {
  display: flex;
  justify-content: flex-end;
  margin-right: 25px;
}

.bt-send,
.bt-send:hover {
  background-color: #33cccc !important;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.bt-close,
.bt-close:hover {
  background-color: #FF4000 !important;
  color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fa-share {
  color: #fff !important;
}

.el-notification__group {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif !important;
}

.box-file {
  display: flex;
  align-items: center;
}

.box-file p {
  color: #707070;
  font-size: 15px;
}

.file-info {
  overflow-wrap: break-word;
  max-width: 48vw;
}

.box-file span {
  color: #a1a1a1;
  font-size: 10px;
}

.el-icon-document {
  font-size: 32px;
  color: #7d7d7d;
  margin-right: 10px;
}

.el-icon-download {
  align-self: flex-end;
  font-size: 21px;
  color: #409eff;
  cursor: pointer;
  margin-left: 15px;
}

/* REPLY DE MENSAGEM */
.box-reply-msg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgb(247, 247, 247);
  border-top: 1px solid #dddddd;
  padding: 20px;
}

.bar-reply {
  width: 5px;
  border-radius: 5px;
  margin-right: 12px;
}

.content-reply {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.content-reply p,
.content-reply span {
  font-size: 14px;
}

.content-reply span {
  text-overflow: ellipsis;
  text-align: start;
  overflow: hidden;
  max-width: 78vw;
}

.bt-reply,
.bt-reply:hover {
  display: none;
  height: 15px;
  cursor: pointer;
}

.box-msg:hover .bt-reply {
  display: block;
}

.el-icon-arrow-down {
  font-weight: 600;
  font-size: 13px;
  color: #a1a1a1;
}

/* MENSAGEM COM ÊNFASE */
.msg-com-animacao {
  animation: popMsg 600ms cubic-bezier(0.075, 0.82, 0.165, 1);
}

.box-msg .lida {
  position: absolute;
  right: 4px;
  bottom: 2px;
  font-size: 11px;
}

#chat-screen {
  /* min-height: 100vh; */
  background-color: #fff;

}

.acoes {
  margin-right: 20px;
}

.acoes i {
  margin-right: 30px;
  font-size: 1.6em;

}

.acoes i:hover {
  cursor: pointer;
}

.chatbox {
  display: flex;
  flex-direction: row;
  background-color: #F7F7F7;
  align-items: center;
  width: 100%;
  height: 105px;
}

.chatbox i {
  margin-right: 30px;
  font-size: 1.6em;
}

.chatbox i:hover {
  cursor: pointer;
}

.recodingDuration {
  margin-right: 10px;
  margin-top: 2px;
  right: 0;
}

.fa-file-audio {
  font-size: 32px;
  color: #7d7d7d;
  margin-right: 10px;
}

.fa-music {
  font-size: 32px;
  color: #7d7d7d;
  margin-right: 10px;
}

@keyframes popMsg {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(1);
  }
}
</style>
